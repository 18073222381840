import useBreakpoint from '../../../../lib/hooks/useBreakpoint'
import MoonSun from './MoonSun'

//COMPONENT
const DayNightSwitch = (): JSX.Element => {
  const { md, lg } = useBreakpoint()
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <MoonSun
        size={lg ? 600 : md ? 500 : 200}
        sunColor="#FFFDF7"
        gradient="#101116 0%, #2F3146 10%, #445473 20%, #5D6F96 35%, #F19D77 65%, #F098AC 85%, #CB93BE 90%, #9CA1BB 96%, #ffffff 100%"
        className="mb-8"
      />
      <MoonSun
        size={lg ? 600 : md ? 500 : 200}
        sunColor="#EBEECF"
        gradient="#101116 0%, #2F3146 10%, #445473 20%, #5D6F96 35%, #75A6D1 65%, #679CC9 85%, #5592C3 90%, #4082BA 96%, #ffffff 100%"
      />
    </div>
  )
}

export default DayNightSwitch
