import Icon from '../../../../organisms/Icon'
import Modal from '../../../../organisms/Modal'
import experience from '../data/experience.json'
import { getTotalExp } from '../functions'

const Experience = (): JSX.Element => (
  <Modal.Wrapper>
    <Modal.Body className="xl:max-h-full p-4 lg:p-8">
      {experience.data.map(item => (
        <div
          key={`${item.start_date} - ${item.end_date}`}
          className="mb-2 lg:mb-5"
        >
          <h3 className="text-xl font-semibold dark:text-dark-Primary mb-6">
            {item.position_name}
          </h3>
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-4/12">
              <div className="text-base font-semibold dark:text-White80 mb-1">
                {item.company_name}
              </div>
              <div className="text-base dark:text-Grey10 font-normal mb-4">
                {item.city}, {item.country}
              </div>
              <div className="text-sm dark:text-Grey font-normal mb-1">
                {item.start_date} - {item.end_date || 'Present'}
              </div>
              <div className="text-sm dark:text-Grey font-normal">
                {item.total_exp ||
                  (item.start_date_no_format &&
                    getTotalExp(item.start_date_no_format))}
              </div>
            </div>
            <div className="lg:w-8/12">
              {item.projects && (
                <>
                  <div className="text-sm lg:text-base font-medium dark:text-White80 mt-3 mb-2 lg:mt-0 lg:mb-5">
                    Developed projects
                  </div>
                  {item.projects.map(project => (
                    <div key={project.name} className="flex mb-4">
                      <div className="pl-2 pr-2 pt-0.5 font-medium"> • </div>
                      <div>
                        <div className="flex text-base font-normal mb-1 dark:text-White80">
                          {project.name}
                          {project.link && (
                            <a
                              className="mr-6 text-White20"
                              href={project.link}
                              target="_blank"
                            >
                              <Icon
                                icon="link"
                                className="dark:text-dark-PrimaryLight hover:dark:text-dark-Primary text-LightBloom150 hover:text-LightBloom200 hover:transition-colors duration-200"
                              />
                            </a>
                          )}
                        </div>
                        <div className="text-sm font-normal max-w-sm dark:text-Grey ">
                          {project.description}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </Modal.Body>
  </Modal.Wrapper>
)

export default Experience
