import c from 'clsx'

import { Switch } from '../../../atoms/Switch'
import Modal from '../../../organisms/Modal'

import { allLinks } from './data'
import useUsefulLinks from './useUsefulLinks'

//COMPONENT
const UsefulLinks = (): JSX.Element => {
  const {
    allCategories,
    activeCategories,
    setSortType,
    visibleLinkCounter,
    linkOffsets,
    setActiveCategories,
    getActiveButtonClass,
    categoriesHandler
  } = useUsefulLinks()

  return (
    <div className="flex h-full flex-col-reverse lg:flex-row">
      <Modal.Wrapper
        className="w-full h-4/6 lg:w-9/12 lg:mr-6 lg:h-full overflow-hidden"
        key="modal_1"
      >
        <Modal.Header
          leftBox="Links"
          rightBox={
            <div className="flex">
              <div className="mr-3">Sort by</div>
              <div className="rounded-md overflow-hidden flex text-sm">
                <button
                  onClick={() => setSortType('Name')}
                  className={c(
                    [getActiveButtonClass('Name')],
                    ['centering w-20 transition-colors']
                  )}
                >
                  Name
                </button>
                <button
                  onClick={() => setSortType('Category')}
                  className={c(
                    [getActiveButtonClass('Category')],
                    ['centering w-20 transition-colors']
                  )}
                >
                  Category
                </button>
              </div>
            </div>
          }
        />
        <Modal.Body className="max-h-modal-content lg:max-h-modal-content-xl relative transition-height duration-400">
          <div
            style={{
              height: `${visibleLinkCounter && visibleLinkCounter * 46 + 30}px`
            }}
          >
            {allLinks.map(link => (
              <a
                key={link.name}
                className={c(
                  [
                    'flex justify-between w-96% max-w-96% py-2 px-3.5 rounded whitespace-nowrap'
                  ],
                  ['border absolute left-2/4'],
                  ['transition-transform duration-400'],
                  ['bg-LightBloom50 hover:bg-LightBloom100op50 border-none'],
                  [
                    'dark:bg-dark-WarningLighter dark:hover:bg-White10 dark:border-Black50'
                  ]
                )}
                style={{
                  transform: `translate(-50%, ${linkOffsets[link.name]}px)`
                }}
                href={link.link}
                target="_blank"
              >
                {link.name}

                <div>{link.category}</div>
              </a>
            ))}
          </div>
        </Modal.Body>
      </Modal.Wrapper>

      <Modal.Wrapper
        className="h-2/6 mb-4 lg:mb-0 lg:w-3/12 lg:h-full"
        key="modal_2"
      >
        <Modal.Header
          leftBox="Categories"
          rightBox={
            <button
              onClick={() => setActiveCategories([])}
              className={c(
                ['centering w-20 transition-colors rounded-md'],
                ['bg-Primary text-LightBloom50'],
                ['dark:bg-dark-Primary dark:text-dark-Black']
              )}
            >
              Clear
            </button>
          }
        />
        <Modal.Body className="max-h-modal-content lg:max-h-modal-content-xl">
          {allCategories.map(categoryName => (
            <button
              key={categoryName}
              className={c(
                [
                  'flex items-center pl-5 font-bold text-sm w-full py-2 lg:py-3'
                ],
                ['border-b last:border-0'],
                ['border-Black border-opacity-10 hover:bg-LightBloom50'],
                [
                  'dark:border-dark-Black dark:border-opacity-10 dark:hover:bg-White10'
                ]
              )}
              onClick={() => categoriesHandler(categoryName)}
            >
              <Switch
                active={activeCategories.some(cat => cat === categoryName)}
                className="mr-2"
              />

              <div>{categoryName}</div>
            </button>
          ))}
        </Modal.Body>
      </Modal.Wrapper>
    </div>
  )
}

export default UsefulLinks
