import c from 'clsx'
export enum BadgeType {
  react = 'React',
  angular = 'Angular',
  next = 'Next.js',
  js = 'JavaScript'
}

interface LabelProps {
  type: BadgeType
}

const Label = ({ type }: LabelProps): JSX.Element => {
  let text
  let style

  if (type === BadgeType.react) {
    text = BadgeType.react
    style = 'border-PrimaryLight text-PrimaryLight'
  }

  if (type === BadgeType.next) {
    text = BadgeType.next
    style = 'border-Black text-Black dark:border-White dark:text-White'
  }

  if (type === BadgeType.angular) {
    text = BadgeType.angular
    style = 'border-Red text-Red'
  }

  if (type === BadgeType.js) {
    text = BadgeType.js
    style = 'border-WarningDark text-WarningDark'
  }

  return (
    <div
      className={c(
        'px-3 py-0.5 border rounded-xl font-semibold text-xs',
        'border-opacity-40 text-opacity-80',
        'dark:border-opacity-60 dark:text-opacity-100',
        style
      )}
    >
      {text}
    </div>
  )
}

export default Label
