import { useEffect } from 'react'
import Icon from '../../../organisms/Icon'
import { GAME, KEYS } from './constants'
import { game } from './game'

const Snake = (): JSX.Element => {
  useEffect(() => {
    game.start()

    return () => {
      game.remove()
    }
  }, [])

  const leftHandler = () => {
    game.keyDownEvents({ code: KEYS.LEFT } as KeyboardEvent)
  }

  const rightHandler = () => {
    game.keyDownEvents({ code: KEYS.RIGHT } as KeyboardEvent)
  }

  const upHandler = () => {
    game.keyDownEvents({ code: KEYS.UP } as KeyboardEvent)
  }

  const downHandler = () => {
    game.keyDownEvents({ code: KEYS.DOWN } as KeyboardEvent)
  }

  return (
    <div className="w-full h-full flex items-center flex-col justify-start">
      <canvas
        id="my-canvas"
        width={GAME.WIDTH}
        height={GAME.HEIGHT}
        className="w-full"
      ></canvas>

      <div className="flex mt-12 lg:hidden flex-col">
        <button onTouchStart={upHandler} className="flex justify-center">
          <Icon
            size={62}
            icon="arrowUp"
            className="dark:text-dark-PrimaryLight text-LightBloom150"
          />
        </button>

        <div className="flex justify-center">
          <button onTouchStart={leftHandler} className="mr-14">
            <Icon
              size={62}
              icon="arrowBack"
              className="dark:text-dark-PrimaryLight text-LightBloom150"
            />
          </button>

          <button onTouchStart={rightHandler}>
            <Icon
              size={62}
              icon="arrowForward"
              className="dark:text-dark-PrimaryLight text-LightBloom150"
            />
          </button>
        </div>

        <button onTouchStart={downHandler} className="flex justify-center">
          <Icon
            size={62}
            icon="arrowDown"
            className="dark:text-dark-PrimaryLight text-LightBloom150"
          />
        </button>
      </div>
    </div>
  )
}

export default Snake
