import Education from './pageParts/Education'
import Experience from './pageParts/Experience'
import LanguagesSkills from './pageParts/LanguagesSkills'
import MainInfo from './pageParts/MainInfo'

//COMPONENT
const Main = (): JSX.Element => {
  return (
    <div className="flex h-full flex-col xl:flex-row xl:overflow-auto scroll-custom xl:h-full xl:max-h-full">
      <div className="xl:w-5/12 xl:pr-2">
        <MainInfo className="mb-5 lg:mb-8 mt-px" />
        <LanguagesSkills className="mb-5 lg:mb-8" />
        <Education className="xl:mb-1" />
      </div>
      <div className="xl:w-7/12 xl:ml-6 mt-5 lg:mt-8 xl:mt-0 mb-5">
        <Experience />
        <div className="h-4"></div>
      </div>
    </div>
  )
}

export default Main
