export const KEYS = {
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  A: 'KeyA',
  D: 'KeyD',
  SPACE: 'Space'
}

export const GAME = {
  WIDTH: 640,
  HEIGHT: 360,
  CELL_SIZE: 18,
  BOARD_SIZE: 15,
  BOARD_PIXEL_SIZE: 18 * 15,
  HEAD_SIZE: 18
}
