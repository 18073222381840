import { SVGProps } from "react"
import c from "clsx"

export default function Folder({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="folder"
      fill="white"
      className={c(["fill-current"], [className && className])}
      {...props}
    >
      <path opacity="0.3" d="M4 6.47021V18.0002H20V10.0002H5.76L4 6.47021Z" />
      <path d="M18 4L20 8H17L15 4H13L15 8H12L10 4H8L10 8H7L5 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V4H18ZM20 18H4V6.47L5.76 10H20V18Z" />
    </svg>
  )
}
