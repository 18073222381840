import c from 'clsx'
import { SVGProps } from 'react'

export default function ArrowBack({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="arrow-back"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M17.5098 3.8701L15.7298 2.1001L5.83984 12.0001L15.7398 21.9001L17.5098 20.1301L9.37984 12.0001L17.5098 3.8701Z" />
    </svg>
  )
}
