const templateTSX = `
import './style.css'

const Spinner = (): JSX.Element => <div className="spinner" />

export default Spinner
`
const templateCSS = `
.spinner{
  display: inline-block;
  width: 46px;
  height: 46px;
  border: 4px solid currentColor;
  border-radius: 50%; 
  border-bottom-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
`
export default { tsx: templateTSX, css: templateCSS }
