import { SVGProps } from "react"
import c from "clsx"

export default function Smile({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      data-icon="menu"
      xmlns="http://www.w3.org/2000/svg"
      className={c(["fill-current"], [className && className])}
      {...props}
    >
      <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" />
    </svg>
  )
}
