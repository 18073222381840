import c from 'clsx'
import { useState } from 'react'
import Clouds from './Clouds'
import Sphere from './Sphere'
import Stars from './Stars'
import { MoonSunProps } from './interfaces'

const MoonSun = ({
  size,
  sunColor,
  gradient,
  className
}: MoonSunProps): JSX.Element => {
  const [sun, setSun] = useState(true)

  return (
    <button
      className={c(
        [
          'relative rounded-full flex items-center cursor-pointer transition-all duration-700 overflow-hidden z-10'
        ],
        [className]
      )}
      style={{
        backgroundImage: `linear-gradient(90deg, ${gradient})`,
        backgroundSize: '250%',
        backgroundPosition: sun ? 'right' : 'left',
        width: size,
        padding: size * 0.045
      }}
      onClick={() =>
        requestAnimationFrame(() => {
          setSun(!sun)
        })
      }
    >
      <Sphere
        size={size * 0.32}
        sun={sun}
        sunColor={sunColor}
        style={{
          transform: `translate(${
            sun ? 0 : size - size * 0.41 // 0.32 - Sun size + 0.09 - Padding
          }px)`
        }}
      />

      <Stars
        size={size * 0.32}
        className={c(
          ['transition-transform absolute'],
          [sun ? 'duration-300' : 'delay-200 duration-700']
        )}
        style={{
          transform: `translate(${sun ? size * -0.6 : 1}px, ${size * 0}px)`
        }}
      />

      <Clouds
        size={size * 0.875}
        className="transition-transform duration-700 absolute"
        style={{
          transform: `translate(${size * 0.3}px,${
            sun ? 0 : size - size * 0.41 // 0.32 - Sun size + 0.09 - Padding
          }px)`
        }}
      />
    </button>
  )
}

export default MoonSun
