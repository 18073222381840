import c from 'clsx'
import { useEffect, useRef, useState } from 'react'
import useBreakpoint from '../../lib/hooks/useBreakpoint'
import Icon from '../organisms/Icon'
export interface AccordionItem {
  id: number
  title: string
  content: string
}

interface AccordionProps {
  items: AccordionItem[]
  onClick?(id: number): void
  navigation: boolean
}

const Accordion = ({
  navigation,
  items,
  onClick
}: AccordionProps): JSX.Element => {
  const [activeItem, setActiveItem] = useState(0)
  const nav = useRef(navigation)
  const { md } = useBreakpoint()

  const refs: {
    [key: number]: React.MutableRefObject<HTMLDivElement | null>
  } = {
    0: useRef(null),
    1: useRef(null),
    2: useRef(null)
  }

  const itemHandler = (id: number) => {
    if (activeItem === id) {
      setActiveItem(-1)
      onClick && onClick(-1)
    } else {
      setActiveItem(id)
      onClick && onClick(id)
    }
  }

  const isActiveItem = (id: number) => {
    return activeItem === id
  }

  const getHeight = (id: number) => {
    const value = refs[id]?.current?.getBoundingClientRect().height

    if (nav.current !== navigation && value && md) {
      setTimeout(() => {
        nav.current = navigation
      })

      if (navigation) {
        return value * 1.2
      } else {
        return value * 0.9
      }
    }

    return value
  }

  useEffect(() => {
    if (activeItem === -1) {
      nav.current = navigation
    }
  }, [navigation])

  return (
    <div className="w-full pb-5">
      {items.map(item => (
        <div
          key={item.id}
          className={c(
            ['overflow-hidden'],
            ['border border-t-0'],
            ['border-White80 dark:border-0 dark:shadow-dark-normal'],
            ['first:rounded-tr-md first:rounded-tl-md first:border-t'],
            ['last:rounded-br-md last:rounded-bl-md']
          )}
        >
          <button
            className={c(
              ['bg-PrimaryLighter dark:bg-dark-White shadow-md'],
              [
                'px-3 py-2 lg:px-5 lg:py-4 text-base w-full flex justify-between'
              ]
            )}
            onClick={() => itemHandler(item.id)}
          >
            <div>{item.title}</div>
            <div>
              <Icon
                className={c(
                  [
                    'flex items-center gap-3 transform transition-all duration-400 opacity-60'
                  ],
                  [isActiveItem(item.id) ? 'rotate-0' : 'rotate-180']
                )}
                icon="arrowUp"
              />
            </div>
          </button>
          <div
            className={c(
              ['bg-White dark:bg-White30 bg-opacity-50 dark:bg-opacity-100'],
              ['transition-height duration-400']
            )}
            style={{
              height: isActiveItem(item.id) ? `${getHeight(item.id)}px` : '0px'
            }}
          >
            <p
              ref={refs[item.id]}
              className={c(['px-5 py-4 whitespace-pre-wrap'])}
            >
              {item.content}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion
