import ICONS, { IconType, SupportedIcon } from './data'

interface IconProps {
  icon: SupportedIcon
  size?: number
  className?: string
  style?: {
    [key: string]: string
  }
}

export default function Icon({
  icon,
  size = 24,
  className,
  ...rest
}: IconProps): JSX.Element {
  const IconEl: IconType = ICONS[icon] || ICONS.placeholder
  return <IconEl width={size} height={size} className={className} {...rest} />
}

export type { SupportedIcon, IconProps }
