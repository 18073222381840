import { Toast } from '../../../../atoms/Toast'
import Icon from '../../../../organisms/Icon'
import Modal from '../../../../organisms/Modal'
import { getTotalExp } from '../functions'

interface MainInfoProps {
  className?: string
}

const MainInfo = ({ className }: MainInfoProps): JSX.Element => {
  return (
    <Modal.Wrapper className={className}>
      <Modal.Body className="max-h-modal-content lg:max-h-modal-content-xl p-4 lg:p-8 flex justify-between">
        <div>
          <h2 className="text-xl lg:text-2xl font-bold mb-1">Viktor Iukhlin</h2>
          <p className="text-sm lg:text-base text-Grey10 mb-3">
            Software Developer
          </p>
          <p className="text-sm lg:text-base text-Grey10 mb-6">
            Experience - {getTotalExp('2019-06-01')}
          </p>
          <div className="flex items-center hover:transition-colors">
            <a
              className="mr-6 text-White20"
              href="https://instagram.com/viktoriukhlin"
              target="_blank"
            >
              <Icon
                icon="instagram"
                className="hover:text-LightBloom150 hover:dark:text-White50 hover:transition-colors duration-200"
              />
            </a>
            <a
              className="mr-6 text-White20"
              href="https://www.linkedin.com/in/viktor-iukhlin/"
              target="_blank"
            >
              <Icon
                icon="linkedIn"
                className="hover:text-LightBloom150 hover:dark:text-White50"
              />
            </a>
            <a
              className="w-5 h-5 mr-6 text-White20"
              href="https://t.me/ViktorIukhlin"
              target="_blank"
            >
              <Icon
                icon="telegram"
                className="hover:text-LightBloom150 hover:dark:text-White50"
              />
            </a>
            <span className="flex text-White30 hover:text-LightBloom150 hover:dark:text-White50 hover:transition-colors duration-200">
              <span className="pt-0.5 text-LightBloom150 dark:text-White50">
                iukhlin.v@gmail.com
              </span>
              <button
                className="ml-1 w-6 h-6 flex justify-center items-center hover:bg-LightBloom100op50 rounded hover:transition-colors duration-200"
                onClick={() => {
                  navigator.clipboard.writeText('iukhlin.v@gmail.com')
                  Toast({
                    title: 'Success',
                    message: 'Mail copied successfully',
                    type: 'success'
                  })
                }}
              >
                <Icon
                  icon="copy"
                  className="w-3 h-3 text-LightBloom150 dark:text-White50"
                />
              </button>
            </span>
          </div>
        </div>
        <div className="hidden lg:inline">
          <div className="w-36 h-36 rounded-md overflow-hidden">
            <img src="photo.webp" alt="" />
          </div>
        </div>
      </Modal.Body>
    </Modal.Wrapper>
  )
}

export default MainInfo
