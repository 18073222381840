export const certificates = [
  {
    id: 1,
    title: 'Estonian A2 Course',
    description:
      'Certificate of successful completion of the Estonian language course at level A2',
    url: './certificatesPage/A2.webp'
  },
  {
    id: 2,
    title: 'Estonian A1 Course',
    description:
      'Certificate of successful completion of the Estonian language course at level A1',
    url: './certificatesPage/A1.webp'
  },
  {
    id: 3,
    title: 'Udemy Canvas Course',
    description:
      'Certificate confirming the complete completion of the Canvas course',
    url: './certificatesPage/Canvas.webp'
  },
  {
    id: 4,
    title: 'Udemy React 2023 Course',
    description:
      'Certificate confirming the complete completion of the React 2023 course',
    url: './certificatesPage/React.webp'
  },
  {
    id: 5,
    title: 'VK Hackathon certificate',
    description: 'VK hackathon participation certificate',
    url: './certificatesPage/VK.webp'
  },
  {
    id: 6,
    title: 'VTB Hackathon certificate',
    description: 'Hackathon finalist certificate',
    url: './certificatesPage/VTB.webp'
  },
  {
    id: 7,
    title: 'SBERCLOUD Hackathon certificate',
    description: 'Hackathon participation certificate',
    url: './certificatesPage/Sbercloud.webp'
  },
  {
    id: 8,
    title: 'Raiffeisen hackathon certificate',
    description: 'Hackathon participation certificate',
    url: './certificatesPage/Raiffeisen.webp'
  }
]
