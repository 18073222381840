export const getDifferenceFromCurrentDate = (dateValue: string) => {
  const date = new Date(dateValue)
  const currentDate = new Date()

  const days = Math.ceil(
    Math.abs(date.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
  )
  const years = Math.floor(days / 365)
  const months = Math.floor((days - years * 365) / 30)

  if (months === 12) {
    return {
      months: 0,
      years: years + 1
    }
  }

  return {
    months: months,
    years: years
  }
}
