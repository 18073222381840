export const random = (minValue: number, maxValue: number) => {
  return Math.floor(Math.random() * (maxValue - minValue + 1) + minValue)
}

export const getWindowHeight = ({
  width,
  height
}: {
  width: number
  height: number
}) => {
  const navBar = localStorage.getItem('navBar') === 'Active' ? true : false
  const widowSize =
    navBar && window.innerWidth > 768
      ? window.innerWidth - 280
      : window.innerWidth

  if (widowSize > width + 72) {
    return { height: `${height}px`, width: `${width}px` }
  } else {
    return { height: `${widowSize * 0.535}px`, width: '100%' }
  }
}
