import React, { useEffect, useState } from 'react'
import { allLinks } from './data'

type Sort = 'Name' | 'Category'

interface LinkOffset {
  [key: string]: number
}

interface LinkOffsetsAndCounter {
  linkOffsets: LinkOffset
  visibleLinkCounter: number
}

interface HookReturn {
  allCategories: string[]
  activeCategories: string[]
  setSortType: React.Dispatch<React.SetStateAction<Sort>>
  visibleLinkCounter: number
  linkOffsets: LinkOffset
  setActiveCategories: React.Dispatch<React.SetStateAction<string[]>>
  getActiveButtonClass(type: string): string
  categoriesHandler(category: string): void
}

const getLinkOffsetsAndCount = (
  sortType: Sort,
  activeCategories: string[]
): LinkOffsetsAndCounter => {
  let offset = 20
  let visibleLinkCounter = 0
  const linkOffsets: LinkOffset = {}

  //if we are going to sort we need to copy the array, otherwise just create a link
  const allLinksArray = sortType === 'Category' ? [...allLinks] : allLinks

  //sorter by category
  if (sortType === 'Category') {
    allLinksArray.sort((a, b) => {
      if (a.category > b.category) return 1
      if (a.category < b.category) return -1
      return 0
    })
  }

  //create each link an offset
  allLinksArray.forEach(link => {
    if (activeCategories.some(cat => cat === link.category)) {
      linkOffsets[link.name] = offset
      visibleLinkCounter += 1
      offset += 46
    } else {
      linkOffsets[link.name] = -50
    }
  })

  return {
    linkOffsets: linkOffsets,
    visibleLinkCounter: visibleLinkCounter
  }
}

const useUsefulLinks = (): HookReturn => {
  const [activeCategories, setActiveCategories] = useState<string[]>(['CSS'])
  const [visibleLinkCounter, setVisibleLinkCounter] = useState<number>(0)
  const [sortType, setSortType] = useState<Sort>('Name')
  const [linkOffsets, setLinkOffsets] = useState<LinkOffset>(
    //init value, all links get offset -50
    allLinks.reduce<LinkOffset>((acc, item) => {
      return { ...acc, [item.name]: -50 }
    }, {})
  )

  //get all categories
  const allCategories = allLinks.reduce<string[]>((acc, item) => {
    if (acc.some(name => name === item.category)) {
      return acc
    } else {
      return [...acc, item.category]
    }
  }, [])
  allLinks.sort((a, b) => {
    if (a.name > b.name) return 1
    if (a.name < b.name) return -1
    return 0
  })

  const categoriesHandler = (category: string) => {
    if (activeCategories.some(cat => cat === category)) {
      setActiveCategories(state => state.filter(cat => cat !== category))
    } else {
      setActiveCategories(state => [...state, category])
    }
  }

  const getActiveButtonClass = (type: string) => {
    if (type === sortType) {
      return 'bg-Primary text-LightBloom50 dark:bg-dark-Primary dark:text-dark-Black'
    } else {
      return 'bg-LightBloom100op50 text-Primary dark:bg-White10 dark:text-dark-Primary'
    }
  }

  useEffect(() => {
    const offsetAndCount = getLinkOffsetsAndCount(sortType, activeCategories)

    setLinkOffsets(offsetAndCount.linkOffsets)

    if (offsetAndCount.visibleLinkCounter < visibleLinkCounter) {
      setTimeout(
        () => setVisibleLinkCounter(offsetAndCount.visibleLinkCounter),
        300
      )
    } else {
      setVisibleLinkCounter(offsetAndCount.visibleLinkCounter)
    }
  }, [activeCategories, sortType])

  return {
    allCategories,
    activeCategories,
    setSortType,
    visibleLinkCounter,
    linkOffsets,
    setActiveCategories,
    getActiveButtonClass,
    categoriesHandler
  }
}

export default useUsefulLinks
