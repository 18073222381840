import { AccordionItem } from '../../../atoms/Accordion'

export const story: AccordionItem[] = [
  {
    id: 0,
    title: '1991 - 2014',
    content:
      '\tI was born in the small town of Komsomolsk-on-Amur in eastern Russia. This is a beautiful small city on the outskirts of the world, not many people even know where it is located, but at one time it was one of the main industrial centers of the country. A small interesting fact, the average temperature in this city in winter can reach -45 and in summer +35.\n\n' +
      '\tI completed elementary school and college here. ' +
      'After that, I served in the army and started working as a CNC operator at the largest aircraft manufacturing company in the country. \n\n' +
      '\tAfter two years of work, I reached certain heights in my work, found my future wife, and together we decided that it was time to move on. The main reason why we decided to leave this city was the desire to learn and develop.'
  },
  {
    id: 1,
    title: '2014 - 2021',
    content:
      '\tMarch 8, 2014 I will remember for a long time, because it was the day when I left the city where I was born in order to continue my journey. ' +
      'After moving to St. Petersburg, I got a job at the largest enterprise for the production of anti-aircraft weapons and worked here for 5 years as a CNC Programmer.\n\n' +
      '\tIn 2018 I started my study of web programming and in 2019 I quit my main job to continue working as a CNC programmer in a small dental company (I made metal dentures and implants) to be able to do orders as a freelance front-end developer.\n\n' +
      '\tIn November 2020, I received an offer to start working in a large IT company as a Frontend Developer. Of course I could not refuse this opportunity, from that day my life completely changed and I began to devote all my time to front-end development.'
  },
  {
    id: 2,
    title: '2021 - Present',
    content:
      '\tIn 2021, thanks to the recommendation of my friends, I had a chance to complete a test task and successfully pass a technical interview at an IT company in Tallinn. After not much deliberation, my wife and I decided that it was time to move on.\n\n' +
      '\tSince that time and to this day I live and work in Estonia in the city of Tallinn. I think that this is a great country and a great city to live and work in, and this city reminds me town where I was born. \n\n' +
      '\tI also want to say here what I have learned in my life: never give up, always keep trying and move on, one day it will definitely pay off.'
  }
]

export const cord = [
  { lat: 50.5926, lng: 137.0345 },
  { lat: 60.0177, lng: 30.309408 },
  { lat: 59.4223, lng: 24.7349 }
]

export const cordZoom = [10, 10, 12]
