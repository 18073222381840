import { getDifferenceFromCurrentDate } from '../../../../lib/functions/date'

export const getTotalExp = (date: string) => {
  const totalExperience = getDifferenceFromCurrentDate(date)

  let years = ''
  if (totalExperience.years !== 0) {
    years = `${totalExperience.years} ${
      totalExperience.years === 1 ? 'year' : 'years'
    } `
  }

  if (totalExperience.months !== 0) {
    return `${years}${totalExperience.months} ${
      totalExperience.months === 1 ? 'month' : 'months'
    }`
  } else {
    return years
  }
}
