import { Link } from '../../../../lib/models'

export const allLinks: Link[] = [
  //Must have
  {
    category: 'Must have',
    name: 'Links collection',
    link: 'https://andreasbm.github.io/web-skills/'
  },
  //HTML
  {
    category: 'HTML',
    name: 'Colorsinspo',
    link: 'https://colorsinspo.com/'
  },
  {
    category: 'HTML',
    name: 'Font2Web',
    link: 'https://www.font2web.com/'
  },
  {
    category: 'HTML',
    name: 'HTMLColorCodes',
    link: 'https://htmlcolorcodes.com/'
  },
  {
    category: 'HTML',
    name: 'Google WebFonts',
    link: 'https://google-webfonts-helper.herokuapp.com/fonts'
  },
  {
    category: 'HTML',
    name: 'Compress JPEG',
    link: 'https://compressjpeg.com/'
  },
  {
    category: 'HTML',
    name: 'Download Vector Logos',
    link: 'https://worldvectorlogo.com/'
  },
  {
    category: 'HTML',
    name: 'Download Vector Logos 2',
    link: 'https://svgporn.com/'
  },
  //CSS
  {
    category: 'CSS',
    name: 'Px to Rem converter',
    link: 'https://nekocalc.com/px-to-rem-converter'
  },
  {
    category: 'CSS',
    name: 'Grid CSS',
    link: 'https://tpverstak.ru/grid/'
  },
  {
    category: 'CSS',
    name: 'Flexbox CSS',
    link: 'https://tpverstak.ru/flex-cheatsheet/'
  },
  {
    category: 'CSS',
    name: 'Animatable CSS Properties',
    link: 'https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_animated_properties'
  },
  {
    category: 'CSS',
    name: 'Pure CSS Loaders',
    link: 'https://loading.io/css/'
  },
  {
    category: 'CSS',
    name: 'CSS Reference',
    link: 'https://cssreference.io/'
  },
  {
    category: 'CSS',
    name: 'Easing Functions',
    link: 'https://easings.net/'
  },
  {
    category: 'CSS',
    name: 'Transition',
    link: 'http://shpargalkablog.ru/2011/07/transformaciya-css.html'
  },
  //JS
  {
    category: 'JS',
    name: 'Can I Use?',
    link: 'https://caniuse.com/'
  },
  {
    category: 'JS',
    name: 'The Modern JavaScript Tutorial',
    link: 'https://javascript.info/'
  },
  {
    category: 'JS',
    name: 'JS Keycode',
    link: 'https://keycode.info/'
  },
  {
    category: 'JS',
    name: 'Regular expression online',
    link: 'https://regex101.com/'
  },
  {
    category: 'JS',
    name: 'Regular expression online 2',
    link: 'https://regexr.com/'
  },
  {
    category: 'JS',
    name: 'You might not need jQuery',
    link: 'https://youmightnotneedjquery.com/'
  },
  //React
  {
    category: 'JS',
    name: 'SVG to React component',
    link: 'https://react-svgr.com/playground/'
  },
  //Design
  {
    category: 'Design',
    name: 'Figma',
    link: 'https://www.figma.com/'
  },
  {
    category: 'Design',
    name: 'Try different back grounds',
    link: 'https://www.toptal.com/designers/subtlepatterns/'
  },
  //Web IDE
  {
    category: 'Web IDE',
    name: 'CodePen',
    link: 'https://codepen.io/'
  },
  //Programming skills
  {
    category: 'Programming skills',
    name: 'CodinGame',
    link: 'https://www.codingame.com/start'
  },
  {
    category: 'Programming skills',
    name: 'HackerRank',
    link: 'https://www.hackerrank.com/'
  },
  {
    category: 'Programming skills',
    name: 'CodeWars',
    link: 'https://www.codewars.com/'
  },
  //Courses
  {
    category: 'Courses',
    name: 'Udemy',
    link: 'https://www.udemy.com/'
  },
  //Simulators
  {
    category: 'Simulators',
    name: 'Keyboard simulator',
    link: 'https://klava.org/delta/#eng_basic'
  },
  //Inspiration
  {
    category: 'Inspiration',
    name: 'Pinterest',
    link: 'https://www.pinterest.com/'
  },
  {
    category: 'Inspiration',
    name: 'Behance',
    link: 'https://www.behance.net/'
  },
  //Freelance
  {
    category: 'Freelance',
    name: 'FL',
    link: 'https://www.fl.ru/'
  },
  //Useful software
  {
    category: 'Useful software',
    name: 'Convert files online',
    link: 'https://onlineconvertfree.com/'
  },
  {
    category: 'Useful software',
    name: 'VideoCard Benchmarks',
    link: 'https://www.videocardbenchmark.net/'
  }
]
