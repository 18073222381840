import Modal from '../../../../organisms/Modal'

interface EducationProps {
  className?: string
}

const Education = ({ className }: EducationProps): JSX.Element => {
  return (
    <Modal.Wrapper className={className}>
      <Modal.Body className="max-h-modal-content lg:max-h-modal-content-xl p-4 lg:p-8">
        <h3 className="text-lg lg:text-xl font-semibold dark:text-White50 mb-2 lg:mb-4">
          Education
        </h3>
        <p className="text-sm lg:text-base font-semibold dark:text-White80 mb-1">
          Saint Petersburg State University of Architecture and Civil
          Engineering
        </p>
        <p className="text-sm lg:text-base dark:text-Grey font-normal">
          Engineering Bachelor's degree Construction, 2014 - 2019
        </p>
      </Modal.Body>
    </Modal.Wrapper>
  )
}

export default Education
