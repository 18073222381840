import { random } from '../gameFunctions'
import { GAME, KEYS } from './constants'
import { ICell, IGameObject } from './interfaces'

export const game: IGameObject = {
  running: false,
  text: null,
  width: GAME.WIDTH,
  height: GAME.HEIGHT,
  cellSize: GAME.CELL_SIZE,
  boardSize: GAME.BOARD_SIZE,
  boardPixelSize: GAME.BOARD_PIXEL_SIZE,
  headSize: GAME.HEAD_SIZE,
  speed: 150,
  bombSpeed: 30,
  ctx: null,
  board: null,
  snake: null,
  sprites: {
    background: null,
    cell: null,
    body: null,
    food: null,
    head: null,
    bomb: null
  },
  sounds: {
    bomb: null,
    food: null,
    theme: null
  },
  setTextFont() {
    if (this.ctx) {
      this.ctx.fillStyle = '#ffffff'
      this.ctx.font = '26px Arial'
    }
  },
  init() {
    game.running = true
    if (!this.ctx) {
      this.ctx = (
        document.getElementById('my-canvas') as HTMLCanvasElement
      ).getContext('2d')

      this.board.create()
      this.snake.create()

      this.board.createFood()
      this.board.createBomb()

      this.setTextFont()

      window.addEventListener('keydown', this.keyDownEvents)
    } else {
      this.snake.cells = []
      this.snake.headDirection = null
      this.snake.create()
    }
  },
  keyDownEvents(e: KeyboardEvent) {
    if (
      e.code === KEYS.LEFT ||
      e.code === KEYS.RIGHT ||
      e.code === KEYS.DOWN ||
      e.code === KEYS.UP
    ) {
      if (!game.running) {
        game.start()
      }
      const currentDirection = game.snake.headDirection

      if (!currentDirection && e.code === KEYS.DOWN) {
        return
      } else if (currentDirection === KEYS.LEFT && e.code === KEYS.RIGHT) {
        return
      } else if (currentDirection === KEYS.RIGHT && e.code === KEYS.LEFT) {
        return
      } else if (currentDirection === KEYS.UP && e.code === KEYS.DOWN) {
        return
      } else if (currentDirection === KEYS.DOWN && e.code === KEYS.UP) {
        return
      } else {
        game.snake.headDirection = e.code
      }
    }
  },
  preload(callback: () => void) {
    let loaded = 0
    const required =
      Object.keys(this.sprites).length + Object.keys(this.sounds).length

    const onResourceLoad = () => {
      ++loaded
      if (loaded >= required) {
        callback()
      }
    }

    this.preLoadSprites(onResourceLoad)
    this.preLoadAudio(onResourceLoad)

    game.text = { message: 'Press any arrow button to start', x: 136, y: 160 }
  },
  preLoadSprites(onResourceLoad: () => void) {
    for (const key in this.sprites) {
      this.sprites[key] = new Image()
      this.sprites[key].src = `snakeFiles/${key}.webp`
      this.sprites[key].addEventListener('load', onResourceLoad)
    }
  },
  preLoadAudio(onResourceLoad: () => void) {
    for (const key in this.sounds) {
      this.sounds[key] = new Audio(`snakeFiles/${key}.mp3`)
      this.sounds[key].volume = 0.5
      this.sounds[key].addEventListener('canplaythrough', onResourceLoad, {
        once: true
      })
      this.sounds[key].load()
    }
  },
  render() {
    if (!this.ctx) return

    this.ctx.clearRect(0, 0, GAME.WIDTH, GAME.HEIGHT)
    this.ctx.drawImage(this.sprites.background, 0, 0)

    // Board
    for (const cell of this.board.cells.flat()) {
      this.ctx.drawImage(this.sprites.cell, cell.x, cell.y)

      if (cell.hasFood) {
        this.ctx.drawImage(this.sprites.food, cell.x, cell.y)
      }

      if (cell.hasBomb) {
        this.ctx.drawImage(this.sprites.bomb, cell.x, cell.y)
      }
    }

    // Snake
    this.renderSnake()

    // Text
    if (this.text) {
      this.ctx.fillText(this.text.message, this.text.x, this.text.y)
    }
  },
  renderSnake() {
    if (!this.ctx) return

    for (const [index, cell] of this.snake.cells.entries()) {
      if (index === 0) {
        const half = this.headSize / 2

        this.ctx.save()
        this.ctx.translate(cell.x, cell.y)
        this.ctx.translate(half, half)
        this.ctx.rotate((this.snake.getHeadRotate() * Math.PI) / 180)
        this.ctx.drawImage(this.sprites.head, -half, -half)
        this.ctx.restore()
      } else {
        this.ctx.drawImage(this.sprites.body, cell.x, cell.y)
      }
    }
  },
  run() {
    if (this.running) {
      window.requestAnimationFrame(() => {
        this.snake.move()
        this.board.bombRerender()
        this.render()

        setTimeout(() => {
          this.run()
        }, this.speed)
      })
    }
  },
  start() {
    this.init()

    this.preload(() => {
      this.run()
    })
  },
  end() {
    this.sounds.bomb.play()

    this.running = false

    game.text = { message: 'GAME OVER', x: 246, y: 160 }

    window.requestAnimationFrame(() => {
      if (!this.ctx) return

      this.ctx.drawImage(
        this.sprites.body,
        this.snake.cells[0].x,
        this.snake.cells[0].y
      )
    })
  },
  remove() {
    this.ctx = null
    this.snake.cells = []
    this.snake.headDirection = null
    this.snake.moving = false
    this.board.cells = []
    this.text = null
    this.running = false
    this.sounds.theme.pause()
    window.removeEventListener('keydown', this.keyDownEvents)
  }
}

game.snake = {
  cells: [],
  headDirection: null,
  moving: false,
  create() {
    const startCells = [
      { row: 7, col: 7 },
      { row: 8, col: 7 }
    ]
    for (const startCell of startCells) {
      this.cells.push(game.board.getCell(startCell.row, startCell.col))
    }
  },
  move() {
    if (!this.headDirection) return

    const cell = this.getNextCell()

    if (
      cell &&
      !this.cells.some((c: ICell) => {
        return c.row === cell.row && c.col === cell.col
      })
    ) {
      this.cells.unshift(cell)

      if (cell.hasFood) {
        game.sounds.food.play()
        game.board.createFood(cell)
      } else if (cell.hasBomb) {
        this.onSnakeStop()
        game.end()
      } else {
        this.cells.pop()
      }
    } else {
      this.onSnakeStop()
      game.end()
    }
  },
  getNextCell() {
    if (!this.moving) {
      this.onSnakeStart()
      game.text = null
    }

    switch (this.headDirection) {
      case KEYS.UP:
        return game.board.getCell(this.cells[0].row - 1, this.cells[0].col)
      case KEYS.DOWN:
        return game.board.getCell(this.cells[0].row + 1, this.cells[0].col)
      case KEYS.LEFT:
        return game.board.getCell(this.cells[0].row, this.cells[0].col - 1)
      case KEYS.RIGHT:
        return game.board.getCell(this.cells[0].row, this.cells[0].col + 1)

      default:
        return (this.headDirection = null)
    }
  },
  getHeadRotate() {
    if (!this.headDirection) return 0

    if (this.headDirection === KEYS.UP) {
      return 0
    } else if (this.headDirection === KEYS.RIGHT) {
      return 90
    } else if (this.headDirection === KEYS.DOWN) {
      return 180
    } else if (this.headDirection === KEYS.LEFT) {
      return 270
    }
  },
  onSnakeStart() {
    this.moving = true
    game.sounds.theme.loop = true
    game.sounds.theme.play()
  },
  onSnakeStop() {
    this.moving = false
    game.sounds.theme.pause()
  }
}

game.board = {
  cells: [],
  bombTimer: 0,
  create() {
    this.getInitCells()

    const offsetX = (game.width - game.boardPixelSize) / 2
    const offsetY = (game.height - game.boardPixelSize) / 2

    for (let row = 0; row < game.boardSize; row++) {
      for (let col = 0; col < game.boardSize; col++) {
        this.cells[row][col] = {
          x: offsetX + col * game.cellSize,
          y: offsetY + row * game.cellSize,
          row: row,
          col: col
        }
      }
    }
  },
  getInitCells() {
    this.cells = Array.from(
      Array(game.boardSize),
      () => new Array(game.boardSize)
    )
  },
  getCell(row: number, col: number) {
    if (
      row < 0 ||
      row > game.boardSize - 1 ||
      col < 0 ||
      col > game.boardSize - 1
    ) {
      return null
    }

    return this.cells[row][col]
  },
  createFood(cell: ICell) {
    if (cell) cell.hasFood = false
    this.getRandomAvailableCell().hasFood = true
  },
  createBomb(cell: ICell) {
    if (cell) {
      cell.hasBomb = false
    } else {
      const cellWithBomb = this.cells.flat().find((cell: ICell) => cell.hasBomb)

      if (cellWithBomb) {
        cellWithBomb.hasBomb = false
      }
    }
    this.getRandomAvailableCell().hasBomb = true
  },
  getRandomAvailableCell() {
    const cell =
      this.cells[random(0, game.boardSize - 1)][random(0, game.boardSize - 1)]

    if (
      game.snake.cells.some(
        (snakeCell: ICell) =>
          (snakeCell.col === cell.col && snakeCell.row === cell.row) ||
          cell.hasBomb ||
          cell.hasFood
      )
    ) {
      return this.getRandomAvailableCell()
    } else {
      return cell
    }
  },
  bombRerender() {
    if (this.bombTimer > game.bombSpeed) {
      this.bombTimer = 0
      this.createBomb()
    } else {
      this.bombTimer++
    }
  }
}
