import { SVGProps } from 'react'
import c from 'clsx'

export default function Telegram({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="telegram"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM10.3584 7.38244C9.38573 7.78699 7.4418 8.62433 4.5266 9.89443C4.05321 10.0827 3.80524 10.2668 3.78266 10.4469C3.74451 10.7512 4.12561 10.8711 4.64458 11.0343C4.71517 11.0565 4.78832 11.0795 4.8633 11.1038C5.37388 11.2698 6.06071 11.464 6.41776 11.4717C6.74164 11.4787 7.10313 11.3452 7.50222 11.0711C10.226 9.2325 11.632 8.30317 11.7203 8.28314C11.7826 8.269 11.8689 8.25123 11.9273 8.3032C11.9858 8.35517 11.98 8.4536 11.9739 8.48C11.9361 8.64095 10.4401 10.0317 9.66595 10.7515C9.42461 10.9759 9.25341 11.135 9.21842 11.1714C9.14002 11.2528 9.06013 11.3298 8.98334 11.4038C8.50899 11.8611 8.15328 12.204 9.00303 12.764C9.41138 13.0331 9.73815 13.2556 10.0641 13.4776C10.4202 13.7201 10.7753 13.9619 11.2347 14.263C11.3518 14.3398 11.4635 14.4195 11.5724 14.4971C11.9867 14.7925 12.359 15.0578 12.8188 15.0155C13.0861 14.9909 13.3621 14.7397 13.5022 13.9903C13.8335 12.2193 14.4847 8.38204 14.6352 6.80081C14.6484 6.66227 14.6318 6.48497 14.6185 6.40714C14.6052 6.32931 14.5773 6.21842 14.4762 6.13633C14.3563 6.03911 14.1714 6.01861 14.0887 6.02007C13.7125 6.02669 13.1355 6.22734 10.3584 7.38244Z"
      />
    </svg>
  )
}
