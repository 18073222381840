import { CraterProps, SphereProps } from './interfaces'
//COMPONENT
const Sphere = ({ size, sun, sunColor, style }: SphereProps): JSX.Element => {
  const Crater = ({ top, left, cSize }: CraterProps) => (
    <div
      className="absolute rounded-full"
      style={{
        backgroundColor: '#F9F9F9',
        boxShadow: `inset ${cSize * 0.07}px ${cSize * 0.07}px ${
          cSize * 0.2
        }px rgba(175, 175, 175, 0.25)`,
        height: cSize,
        width: cSize,
        top,
        left
      }}
    ></div>
  )

  // Sun - Moon
  return (
    <div
      className="rounded-full relative overflow-hidden transition-all duration-700 ease-toggle"
      style={{
        backgroundColor: '#EDEDED',
        boxShadow: `0px 0px ${size * 0.5}px ${
          sun ? '#FCF2D9' : 'rgba(255, 255, 255, 0.85)'
        }`,
        height: size,
        width: size,
        ...style
      }}
    >
      <div
        className="rounded-full absolute"
        style={{
          backgroundColor: '#FDFDFD',
          bottom: size * 0.125,
          right: size * 0.0625,
          height: size,
          width: size
        }}
      ></div>
      <Crater top={size * 0.0625} left={size * 0.5625} cSize={size * 0.125} />
      <Crater top={size * 0.375} left={size * 0.25} cSize={size * 0.1875} />
      <Crater top={size * 0.5625} left={size * 0.4375} cSize={size * 0.125} />

      <div
        className="rounded-full relative transition-opacity duration-700"
        style={{
          backgroundColor: sunColor,
          opacity: sun ? 1 : 0,
          height: size,
          width: size
        }}
      ></div>
    </div>
  )
}

export default Sphere
