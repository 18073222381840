import { SVGProps } from "react"
import c from "clsx"

export default function Brightness5({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="brightness-5"
      fill="white"
      className={c(["fill-current"], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M18 9.52002V6.00002H14.48L12 3.52002L9.52002 6.00002H6.00002V9.52002L3.52002 12L6.00002 14.48V18H9.52002L12 20.48L14.48 18H18V14.48L20.48 12L18 9.52002ZM12 17.5C8.97002 17.5 6.50002 15.03 6.50002 12C6.50002 8.97002 8.97002 6.50002 12 6.50002C15.03 6.50002 17.5 8.97002 17.5 12C17.5 15.03 15.03 17.5 12 17.5Z"
      />
      <path d="M19.9999 8.68994V3.99994H15.3099L11.9999 0.689941L8.68994 3.99994H3.99994V8.68994L0.689941 11.9999L3.99994 15.3099V19.9999H8.68994L11.9999 23.3099L15.3099 19.9999H19.9999V15.3099L23.3099 11.9999L19.9999 8.68994ZM17.9999 14.4799V17.9999H14.4799L11.9999 20.4799L9.51994 17.9999H5.99994V14.4799L3.51994 11.9999L5.99994 9.51994V5.99994H9.51994L11.9999 3.51994L14.4799 5.99994H17.9999V9.51994L20.4799 11.9999L17.9999 14.4799ZM11.9999 6.49994C8.96994 6.49994 6.49994 8.96994 6.49994 11.9999C6.49994 15.0299 8.96994 17.4999 11.9999 17.4999C15.0299 17.4999 17.4999 15.0299 17.4999 11.9999C17.4999 8.96994 15.0299 6.49994 11.9999 6.49994ZM11.9999 15.4999C10.0699 15.4999 8.49994 13.9299 8.49994 11.9999C8.49994 10.0699 10.0699 8.49994 11.9999 8.49994C13.9299 8.49994 15.4999 10.0699 15.4999 11.9999C15.4999 13.9299 13.9299 15.4999 11.9999 15.4999Z" />
    </svg>
  )
}
