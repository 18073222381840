import { FC } from 'react'
import { SupportedIcon } from './components/organisms/Icon'

// About me
import Main from './components/pages/AboutMe/Main'
import Projects from './components/pages/AboutMe/Projects'
import Story from './components/pages/AboutMe/Story'

// Games
import Arkanoid from './components/pages/Games/Arkanoid'
import Snake from './components/pages/Games/Snake'

// Help Base
import UsefulLinks from './components/pages/HelpBase/UsefulLinks'

// React Components
import MyCertificates from './components/pages/AboutMe/MyCertificates'
import DayNightToggle from './components/pages/Experiments/DayNightToggle'
import SnakeVer2 from './components/pages/Games/SnakeNew'
import ReactComponents from './components/pages/ReactComponents'

export interface ComponentProps {
  navActive: boolean
}

export interface rout {
  icon: SupportedIcon
  nameGroup: string
  paths: {
    path: string
    search?: string
    name: string
    Component: FC<ComponentProps>
  }[]
}

const routes: rout[] = [
  {
    nameGroup: 'About Me',
    icon: 'user',
    paths: [
      { path: '/', name: 'Main', Component: Main },
      { path: '/projects', name: 'Projects', Component: Projects },
      { path: '/story', name: 'Story', Component: Story },
      {
        path: '/certificates',
        name: 'Certificates',
        Component: MyCertificates
      }
    ]
  },
  {
    nameGroup: 'Help Base',
    icon: 'book',
    paths: [
      {
        path: '/useful-links',
        name: 'Useful Links',
        Component: UsefulLinks
      }
    ]
  },
  {
    nameGroup: 'React components',
    icon: 'carousel',
    paths: [
      {
        path: '/react-components',
        search: '?Spinner',
        name: 'Spinner',
        Component: ReactComponents
      },
      {
        path: '/react-components',
        search: '?ProgressIndicator',
        name: 'ProgressIndicator',
        Component: ReactComponents
      }
    ]
  },
  {
    nameGroup: 'Games',
    icon: 'puzzle',
    paths: [
      {
        path: '/arkanoid',
        name: 'Arkanoid',
        Component: Arkanoid
      },
      {
        path: '/snake',
        name: 'Snake',
        Component: Snake
      }
      // {
      //   path: '/snake-new',
      //   name: 'SnakeVer2',
      //   Component: SnakeVer2
      // }
    ]
  },
  {
    nameGroup: 'Experiments',
    icon: 'biotech',
    paths: [
      {
        path: '/day-night-toggle',
        name: 'Day-Night Toggle',
        Component: DayNightToggle
      }
    ]
  }
]

export default routes
