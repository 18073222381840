import { SVGProps } from 'react'
import c from 'clsx'

export default function Book({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="book"
      fill="white"
      className={c(['fill-current'], [className && className])}
      {...props}
    >
      <path d="M21 5C19.89 4.65 18.67 4.5 17.5 4.5C15.55 4.5 13.45 4.9 12 6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V6C22.4 5.55 21.75 5.25 21 5ZM3 18.5V7C4.1 6.65 5.3 6.5 6.5 6.5C7.84 6.5 9.63 6.91 11 7.49V18.99C9.63 18.41 7.84 18 6.5 18C5.3 18 4.1 18.15 3 18.5ZM21 18.5C19.9 18.15 18.7 18 17.5 18C16.16 18 14.37 18.41 13 18.99V7.49C14.37 6.9 16.16 6.5 17.5 6.5C18.7 6.5 19.9 6.65 21 7V18.5Z" />
      <path
        opacity="0.3"
        d="M11 7.49C9.63 6.91 7.84 6.5 6.5 6.5C5.3 6.5 4.1 6.65 3 7V18.5C4.1 18.15 5.3 18 6.5 18C7.84 18 9.63 18.41 11 18.99V7.49Z"
      />
    </svg>
  )
}
