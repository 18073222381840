import ArrowBack from './icons/ArrowBack'
import ArrowDown from './icons/ArrowDown'
import ArrowForward from './icons/ArrowForward'
import ArrowUp from './icons/ArrowUp'
import Biotech from './icons/Biotech'
import Book from './icons/Book'
import Close from './icons/Close'
import Copy from './icons/Copy'
import Folder from './icons/Folder'
import GitHub from './icons/GitHub'
import Img from './icons/Img'
import Instagram from './icons/Instagram'
import Link from './icons/Link'
import LinkedIn from './icons/LinkedIn'
import Placeholder from './icons/PlaceHolder'
import Puzzle from './icons/Puzzle'
import Smile from './icons/Smile'
import Telegram from './icons/Telegram'
import TripOrigin from './icons/TripOrigin'
import User from './icons/User'
import Carousel from './icons/ViewCarousel'

const ICONS = {
  placeholder: Placeholder,
  folder: Folder,
  smile: Smile,
  book: Book,
  carousel: Carousel,
  puzzle: Puzzle,
  user: User,
  instagram: Instagram,
  linkedIn: LinkedIn,
  telegram: Telegram,
  copy: Copy,
  link: Link,
  arrowBack: ArrowBack,
  arrowForward: ArrowForward,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  tripOrigin: TripOrigin,
  img: Img,
  close: Close,
  github: GitHub,
  biotech: Biotech
}

export default ICONS

export type IconType = (typeof ICONS)[keyof typeof ICONS]
export type SupportedIcon = keyof typeof ICONS
