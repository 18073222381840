import 'leaflet/dist/leaflet.css'
import Modal from '../../../organisms/Modal'

import L from 'leaflet'
import { useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { ComponentProps } from '../../../../routes'
import Accordion from '../../../atoms/Accordion'
import { cord, cordZoom, story } from './data'

//COMPONENT
const Story = ({ navActive }: ComponentProps): JSX.Element => {
  const [center, setCenter] = useState(cord[0])
  const [zoom, setZoom] = useState(cordZoom[0])

  const MapHandler = () => {
    const map = useMap()

    if (center) {
      map.flyTo(center, zoom, {
        animate: true
      })

      return (
        <Marker
          position={center}
          icon={L.icon({
            iconUrl: 'marker.svg',
            iconSize: [40, 40],
            iconAnchor: [20, 40]
          })}
        >
          {/* <Popup>You are here</Popup> */}
        </Marker>
      )
    } else {
      map.flyTo({ lat: 58.5, lng: 24.5 }, 7, {
        animate: true
      })

      return null
    }
  }

  const action = (id: number) => {
    setCenter(cord[id] ?? null)
    setZoom(cordZoom[id])
  }

  return (
    <div className="h-full lg:flex lg:flex-row">
      <div className="h-full mr-10 w-full lg:w-6/12">
        <Accordion items={story} onClick={action} navigation={navActive} />
      </div>

      <Modal.Wrapper
        className="mb-4 h-full lg:mb-0 lg:w-6/12 hidden lg:inline"
        key="modal_2"
      >
        <Modal.Body className="h-full">
          <MapContainer
            style={{ height: '100%', width: '100%' }}
            center={center}
            zoom={zoom}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='&copy; <a href="https://carto.com/">carto.com</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
              className="dark:map-tiles"
            />
            <MapHandler />
          </MapContainer>
        </Modal.Body>
      </Modal.Wrapper>
    </div>
  )
}

export default Story
