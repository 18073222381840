import c from "clsx"
import { FC, HTMLProps } from "react"

type SwitchTypes =
  | "primary"
  | "success"
  | "error"
  | "warning"
  | "info"
  | "disabled"

type Switch = {
  [key in SwitchTypes]: {
    background: string
    border: string
  }
}

const SWITCH_TYPES: Switch = {
  primary: {
    background: "bg-Primary dark:bg-dark-Primary",
    border: "border-PrimaryDark dark:border-dark-PrimaryDark"
  },
  success: {
    background: "Success",
    border: "SuccessDark"
  },
  error: {
    background: "Error",
    border: "ErrorDark"
  },
  warning: {
    background: "Warning",
    border: "WarningDark"
  },
  info: {
    background: "Info",
    border: "InfoDark"
  },
  disabled: {
    background: "bg-LightBloom50 dark:bg-dark-LightBloom50",
    border: "border-LightBloom100 dark:border-dark-LightBloom100"
  }
}

interface ArrowProps extends HTMLProps<HTMLDivElement> {
  active: boolean
  callback?(): void
  type?: SwitchTypes
}

export const Switch: FC<ArrowProps> = ({
  active,
  callback,
  type = "primary",
  className
}) => {
  return (
    <div
      className={c(
        ["h-2 w-8 bg-LightBloom100op50 rounded-full relative cursor-pointer"],
        [className && className]
      )}
      onClick={callback && callback}
    >
      <div
        className={c(
          [
            "w-4.5 h-4.5 rounded-full absolute border -top-1.25 transition-all shadow-normal dark:shadow-dark-normal"
          ],
          [active ? "left-3.5" : "left-0"],
          [`${SWITCH_TYPES[active ? type : "disabled"].background}`],
          [`${SWITCH_TYPES[active ? type : "disabled"].border}`]
        )}
      ></div>
    </div>
  )
}
