const templateTSX = `
import './style.css'

export interface ProgressIndicatorProps {
  current: number
  total: number
  diameter: number
  borderWidth: number
}

const ProgressIndicator = ({
  current,
  total,
  diameter,
  borderWidth
}: ProgressIndicatorProps): JSX.Element => {
  const radius = diameter / 2
  const innerRadius = radius - borderWidth

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="transparent"
      strokeWidth={borderWidth}
    >
      <g transform={\`rotate(-90 \${radius} \${radius})\`}>
        <circle
          cx={radius}
          cy={radius}
          r={innerRadius}
          strokeDasharray={
            2 * Math.PI * innerRadius * (current / total) + ' 1000'
          }
          className="indicator__circle"
        />
      </g>
      <text
        x={radius}
        y={radius} 
        textAnchor="middle"
        dominantBaseline="central"
        className="indicator__text"
      >
        {current}/{total}
      </text>
    </svg>
  )
}

export default ProgressIndicator
`
export const templateCSS = `
.indicator__circle {
  stroke: currentColor;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.indicator__text{
  fill: currentColor;
}
`

export default { tsx: templateTSX, css: templateCSS }
