import { useEffect, useState } from 'react'
import { ComponentProps } from '../../../../routes'
import Icon from '../../../organisms/Icon'
import { getWindowHeight } from '../gameFunctions'
import { GAME, KEYS } from './constants'
import { game } from './game'

const Arkanoid = ({ navActive }: ComponentProps): JSX.Element => {
  const [{ height, width }, setSize] = useState({ height: '', width: '' })

  useEffect(() => {
    const resizeCanvasHeight = () => {
      setSize(getWindowHeight({ width: GAME.WIDTH, height: GAME.HEIGHT }))
    }

    game.start()
    window.addEventListener('resize', resizeCanvasHeight)
    return () => {
      game.remove()
      window.removeEventListener('resize', resizeCanvasHeight)
    }
  }, [])

  useEffect(() => {
    setSize(getWindowHeight({ width: GAME.WIDTH, height: GAME.HEIGHT }))
  }, [navActive])

  const leftHandler = () => {
    game.platform.start(KEYS.LEFT)
  }

  const rightHandler = () => {
    game.platform.start(KEYS.RIGHT)
  }

  const spaceHandler = () => {
    game.keyDownEvents({ code: KEYS.SPACE } as KeyboardEvent)
  }

  const stopHandler = () => {
    game.platform.stop()
  }

  return (
    <div className="w-full h-full flex items-center flex-col justify-start">
      <canvas
        id="my-canvas"
        width={GAME.WIDTH}
        height={GAME.HEIGHT}
        style={{ height: `${height}`, width: `${width}` }}
      ></canvas>

      <div className="inline mt-12 lg:hidden">
        <button onTouchStart={leftHandler} onTouchEnd={stopHandler}>
          <Icon
            size={62}
            icon="arrowBack"
            className="dark:text-dark-PrimaryLight text-LightBloom150"
          />
        </button>
        <button onTouchStart={spaceHandler} onTouchEnd={stopHandler}>
          <Icon
            size={62}
            icon="tripOrigin"
            className="dark:text-dark-PrimaryLight text-LightBloom150 mx-10"
          />
        </button>
        <button onTouchStart={rightHandler} onTouchEnd={stopHandler}>
          <Icon
            size={62}
            icon="arrowForward"
            className="dark:text-dark-PrimaryLight text-LightBloom150"
          />
        </button>
      </div>
    </div>
  )
}

export default Arkanoid
