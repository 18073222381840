import c from 'clsx'

interface ProgressBarProps {
  max: number
  curent: number
  className: string
}

const ProgressBar = ({
  max,
  curent,
  className
}: ProgressBarProps): JSX.Element => {
  const progres = (curent / max) * 100

  return (
    <div
      className={c(
        'w-full h-1 rounded-sm dark:bg-White20 bg-LightBloom100',
        className
      )}
    >
      <div
        style={{ width: `${progres}%` }}
        className="rounded-sm h-full dark:bg-dark-PrimaryDark bg-PrimaryDark"
      ></div>
    </div>
  )
}

export default ProgressBar
