import c from 'clsx'
import { SVGProps } from 'react'

export default function ArrowDown({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="arrow-down"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M3.8701 6.49016L2.1001 8.27016L12.0001 18.1602L21.9001 8.26016L20.1301 6.49016L12.0001 14.6202L3.8701 6.49016V6.49016Z" />
    </svg>
  )
}
