import c from 'clsx'
import { SVGProps } from 'react'

export default function Biotech({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="biotech"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M12.8645 4.51933L11.9248 4.86133L13.6348 9.55983L14.5745 9.21783L12.8645 4.51933Z"
      />
      <path
        opacity="0.3"
        d="M10.5 9C11.0523 9 11.5 8.55228 11.5 8C11.5 7.44772 11.0523 7 10.5 7C9.94772 7 9.5 7.44772 9.5 8C9.5 8.55228 9.94772 9 10.5 9Z"
      />
      <path d="M7 19C5.9 19 5 19.9 5 21H19C19 19.9 18.1 19 17 19H13V17H16C17.1 17 18 16.1 18 15H10C8.34 15 7 13.66 7 12C7 10.91 7.59 9.96 8.47 9.43C8.88 10.02 9.53 10.43 10.3 10.49C11 10.55 11.66 10.3 12.15 9.87L12.74 11.48L13.68 11.14L14.02 12.08L15.9 11.4L15.56 10.46L16.5 10.12L13.76 2.6L12.82 2.94L12.48 2L10.6 2.68L10.94 3.62L10 3.97L10.56 5.52C9.39 5.48 8.37 6.27 8.08 7.38C6.27 8.14 5 9.92 5 12C5 14.76 7.24 17 10 17V19H7ZM12.86 4.52L14.57 9.22L13.63 9.56L11.92 4.86L12.86 4.52ZM10.5 7C11.05 7 11.5 7.45 11.5 8C11.5 8.55 11.05 9 10.5 9C9.95 9 9.5 8.55 9.5 8C9.5 7.45 9.95 7 10.5 7Z" />
    </svg>
  )
}
