import { IProject } from '../../../../lib/models'
import { BadgeType } from './Label'

export const projects: IProject[] = [
  {
    id: 1,
    title: 'Masabi Justride Retail',
    subtitle: 'Mobile app for Android and iOS plus Web application',
    description:
      'Masabi offers SaaS ticketing solutions to public transport agencies and operators around the globe. Their platform, Justride, allows passengers to use a mobile phone, contactless bank card, or smartcard and simply tap to travel. \n\nMasabi is bringing this market-leading fare collection experience to every passenger and to every transit agency around the globe without prohibitive costs or taking years to deliver.',
    badgeType: BadgeType.angular,
    images: [
      './photosOfProjects/masabi_1.webp',
      './photosOfProjects/masabi_2.webp',
      './photosOfProjects/masabi_3.webp',
      './photosOfProjects/masabi_4.webp',
      './photosOfProjects/masabi_5.webp'
    ],
    icon: 'masabi_icon',
    technology: [
      'Angular',
      'Ionic',
      'TypeScript',
      'Jest',
      'Capacitor',
      'Android',
      'iOS',
      'Git',
      'GitLab'
    ],
    bgColor: '#ededed',
    link: 'https://www.masabi.com/justride-app/'
  },
  {
    id: 2,
    title: 'Projects inside Mooncascade',
    subtitle: 'Many interesting projects with different technologies',
    description:
      'Mooncascade was founded in 2009 by four software engineers with a passion for high-quality coding. Despite starting out during the worst economic crisis since the Great Depression, we were confident that we could deliver products that truly would make an impact. \n\nToday, Mooncascade is an award-winning advisory, design, and software development company with a global reach. Our skilled team delivers quality results at every stage of the software product development process, helping clients build innovative solutions that inspire, disrupt, and challenge markets across the world. \n\nWe don’t just deliver products. We deliver impact.',
    badgeType: BadgeType.next,
    images: [
      './photosOfProjects/mooncascade_1.webp',
      './photosOfProjects/mooncascade_2.webp',
      './photosOfProjects/mooncascade_3.webp'
    ],
    icon: 'mooncascade_icon',
    technology: [
      'Next.js',
      'TypeScript',
      'TailwindCss',
      'Jest',
      'Cypress',
      'Node.js',
      'Amplitude',
      'Storybook',
      'Git',
      'GitLab'
    ],
    bgColor: '#000000',
    link: 'https://www.mooncascade.com/'
  },
  {
    id: 4,
    title: 'Bet Better',
    subtitle: 'Website based on React and SCSS',
    description:
      'Freelance project developed by me, one back-end developer and one designer. Quite an interesting experience, since there were a lot of challenges for us.',
    badgeType: BadgeType.react,
    images: [
      './photosOfProjects/betbetter_0.webp',
      './photosOfProjects/betbetter_1.webp',
      './photosOfProjects/betbetter_2.webp',
      './photosOfProjects/betbetter_3.webp',
      './photosOfProjects/betbetter_4.webp',
      './photosOfProjects/betbetter_5.webp',
      './photosOfProjects/betbetter_6.webp',
      './photosOfProjects/betbetter_7.webp',
      './photosOfProjects/betbetter_8.webp',
      './photosOfProjects/betbetter_9.webp',
      './photosOfProjects/betbetter_10.webp'
    ],
    icon: 'betbetter_icon',
    technology: [
      'React',
      'JavaScript',
      'SCSS',
      'REST API',
      'Atomic design',
      'Axios',
      'Git',
      'GitHub'
    ]
  },
  {
    id: 5,
    title: 'KLS Martin',
    subtitle: 'Website based on JavaScript, Pug and SASS',
    description:
      'It was a complex and fairly large project. The main task here was to completely refactor the project using a framework specially developed for this project. \n\nIt was one of the first freelance projects, good development experience and good teamwork practice.',
    badgeType: BadgeType.js,
    images: [
      './photosOfProjects/kls_0.webp',
      './photosOfProjects/kls_1.webp',
      './photosOfProjects/kls_2.webp',
      './photosOfProjects/kls_3.webp'
    ],
    icon: 'kls_icon',
    bgColor: '#263238',
    technology: [
      'JavaScript',
      'Pug.js',
      'SASS',
      'REST API',
      'Git',
      'GitExtensions'
    ],
    link: 'https://klsmartin.su/'
  },
  {
    id: 6,
    title: 'Data Center Control',
    subtitle:
      'Large web project developed by me and a small team for a freelance client',
    description:
      'Absolutely great experience and interesting project. The main task was to develop an admin panel for managing a large warehouse of various goods. Also management of the rights of personnel and supplies.\n\nAnd, thanks to this project, I learned how to work with a client and cope with a large load in a short time. \n\nMy tasks were: \n  - Development of the opportunity to track the movement of consoles. \n  - Development of the opportunity to add new users with different rights. \n  - Development of the opportunity to create and edit console shipments. \n  - Graphical representation of the customer`s warehouses. \n  - Creating data visualization reports on the state of consoles using the Chart.js library. \n  - Creation of tables with the ability to add and remove fields, rows. \n  - Continuous communication with the client and client employees to create a better experience using the application. \n  - Teamwork with backend developers, using REST API',
    badgeType: BadgeType.react,
    images: [
      './photosOfProjects/data_1.webp',
      './photosOfProjects/data_2.webp',
      './photosOfProjects/data_3.webp',
      './photosOfProjects/data_4.webp',
      './photosOfProjects/data_5.webp',
      './photosOfProjects/data_6.webp',
      './photosOfProjects/data_7.webp',
      './photosOfProjects/data_9.webp',
      './photosOfProjects/data_10.webp'
    ],
    icon: 'data_icon',
    technology: [
      'React',
      'Core UI',
      'SCSS',
      'REST API',
      'Chart.js',
      'Animate.css',
      'Axios',
      'Git',
      'GitHub'
    ]
  },
  {
    id: 7,
    title: 'Appvelox',
    subtitle: 'Website based on React and SCSS',
    description:
      'The main task of this project was to develop a prototype of the patient`s personal account.',
    badgeType: BadgeType.react,
    images: [
      './photosOfProjects/appvelox_0.webp',
      './photosOfProjects/appvelox_1.webp'
    ],
    icon: 'appvelox_icon',
    bgColor: '#003B72',
    technology: ['React', 'JavaScript', 'SCSS', 'REST API', 'Git', 'GitHub']
  },
  {
    id: 8,
    title: 'Todo list',
    subtitle: 'Website based on React and SCSS',
    description:
      'Just one of the many mini-projects that I have developed for myself.',
    badgeType: BadgeType.react,
    images: [
      './photosOfProjects/todo_0.webp',
      './photosOfProjects/todo_1.webp',
      './photosOfProjects/todo_2.webp',
      './photosOfProjects/todo_3.webp',
      './photosOfProjects/todo_4.webp'
    ],
    icon: 'todo_icon',
    technology: [
      'React',
      'TypeScript',
      'SCSS',
      'Storybook',
      'Apollo',
      'GraphQL',
      'Git',
      'GitHub'
    ],
    link: 'https://viktoriukhlin.github.io/todos-list/',
    github: 'https://github.com/ViktorIukhlin/todos-list'
  },
  {
    id: 9,
    title: 'Mooncascade test task',
    subtitle: 'Two clients and one server based on Node.js',
    description:
      'One of the best test task I have ever done. The main goal of this task was to connect two clients with each other using web sockets, correctly receive and display data from the first client to the second one in real time. \n\nAlso it was tasked to track the user`s movement through the tabs in browser and to display warnings that the connection was closed and opened while the user was away.',
    badgeType: BadgeType.react,
    images: [
      './photosOfProjects/moon_0.webp',
      './photosOfProjects/moon_1.webp',
      './photosOfProjects/moon_2.webp'
    ],
    icon: 'moon_icon',
    bgColor: '#161623',
    technology: [
      'React',
      'TypeScript',
      'SCSS',
      'Node.js',
      'WebSocket',
      'Concurrently',
      'Git',
      'GitHub'
    ],
    github: 'https://github.com/ViktorIukhlin/Mooncascade'
  },
  {
    id: 10,
    title: 'Cocky Jun',
    subtitle: 'Web application based on React and SCSS',
    description:
      'A fun hackathon app developed by me and my team. It was 2 intensive days, during which we had to develop a prototype application to test developers for knowledge of Java script and English. \n\nAs a result, we got to the semi-finals thanks to this project.',
    badgeType: BadgeType.react,
    images: [
      './photosOfProjects/jun_0.webp',
      './photosOfProjects/jun_1.webp',
      './photosOfProjects/jun_2.webp',
      './photosOfProjects/jun_3.webp',
      './photosOfProjects/jun_4.webp'
    ],
    icon: 'jun_icon',
    technology: ['React', 'JavaScript', 'SCSS', 'Git', 'GitHub'],
    link: 'https://viktoriukhlin.github.io/CockyJun/#/',
    github: 'https://github.com/ViktorIukhlin/CockyJun'
  },
  {
    id: 11,
    title: 'My First Site',
    subtitle: 'Website based on HTML, CSS and Java Script',
    description:
      'My first portfolio site. It`s so nice to see where it all started. :)',
    badgeType: BadgeType.js,
    images: [
      './photosOfProjects/my_0.webp',
      './photosOfProjects/my_1.webp',
      './photosOfProjects/my_2.webp'
    ],
    icon: 'my_icon',
    technology: ['HTML', 'CSS', 'JavaScript', 'Git', 'GitHub'],
    link: 'https://viktoriukhlin.github.io/My_Site/'
  }
]
