import c from 'clsx'
import { HTMLProps } from 'react'

interface HeaderProps extends HTMLProps<HTMLDivElement> {
  leftBox?: JSX.Element | string
  rightBox?: JSX.Element
}

const Header = ({ leftBox, rightBox, className }: HeaderProps): JSX.Element => (
  <div
    className={c(
      [
        'text-sm lg:text-base font-bold border-b border-opacity-10 p-2 lg:p-3 bg'
      ],
      [leftBox && rightBox && 'flex justify-between'],
      ['border-Black text-Black'],
      ['dark:border-dark-Black dark:text-dark-Black dark:border-opacity-10'],
      [className]
    )}
  >
    {leftBox && <div>{leftBox}</div>}
    {rightBox && <div>{rightBox}</div>}
  </div>
)

const Body = ({
  children,
  className,
  ...props
}: HTMLProps<HTMLDivElement>): JSX.Element => (
  <div className={c(['scroll-custom overflow-auto'], [className])} {...props}>
    {children}
  </div>
)

const Wrapper = ({
  children,
  className
}: HTMLProps<HTMLDivElement>): JSX.Element => (
  <div
    className={c(
      ['scroll-custom rounded-md'],
      ['bg-White shadow-normal'],
      ['dark:bg-dark-White dark:shadow-dark-normal overflow-hidden'],

      [className]
    )}
  >
    {children}
  </div>
)

export default { Wrapper, Header, Body }
