export default function Stand({
  displayHeight
}: {
  displayHeight: number
}): JSX.Element {
  return (
    <svg
      width={displayHeight * 0.5}
      height={displayHeight * 0.335}
      viewBox="0 0 706 606"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_109_14)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M706 0H0V565H706V0Z"
          fill="url(#paint0_linear_109_14)"
        />
      </g>
      <rect
        y="564.5"
        width="706"
        height="37"
        fill="url(#paint1_linear_109_14)"
      />
      <path
        d="M605 601.5H697V604.5C697 605.052 696.552 605.5 696 605.5H606C605.448 605.5 605 605.052 605 604.5V601.5Z"
        fill="black"
      />
      <path
        d="M9 601.5H101V604.5C101 605.052 100.552 605.5 100 605.5H10C9.44772 605.5 9 605.052 9 604.5V601.5Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_i_109_14"
          x="0"
          y="0"
          width="706"
          height="566"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.21 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_109_14"
          />
        </filter>
        <linearGradient
          id="paint0_linear_109_14"
          x1="353"
          y1="-1488.84"
          x2="353"
          y2="565.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E1E3" />
          <stop offset="0.979437" stopColor="#D0D1D3" />
          <stop offset="0.986526" stopColor="#E0E1E3" />
          <stop offset="0.991232" stopColor="#D2D3D5" />
          <stop offset="0.999923" stopColor="#6D6E70" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_109_14"
          x1="-3.47643e-06"
          y1="583.5"
          x2="706"
          y2="583.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CECFD1" />
          <stop offset="0.0169943" stopColor="#A2A3A5" />
          <stop offset="0.0253902" stopColor="#A9AAAC" />
          <stop offset="0.0482365" stopColor="#DBDCDE" />
          <stop offset="0.0582469" stopColor="#DCDDDF" />
          <stop offset="0.0693067" stopColor="#D6D7D9" />
          <stop offset="0.0881166" stopColor="#C1C2C4" />
          <stop offset="0.924147" stopColor="#BEBFC1" />
          <stop offset="0.946563" stopColor="#E5E6EA" />
          <stop offset="0.964264" stopColor="#D4D5D7" />
          <stop offset="0.978491" stopColor="#A6A7A9" />
          <stop offset="0.983702" stopColor="#A2A3A5" />
          <stop offset="1" stopColor="#CDCED0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
