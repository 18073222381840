import { SVGProps } from "react"
import c from "clsx"

export default function Brightness2({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="brightness-2"
      fill="white"
      className={c(["fill-current"], [className && className])}
      {...props}
    >
      <path
        opacity="0.3"
        d="M18.0002 12C18.0002 7.59 14.4102 4 10.0002 4C9.66023 4 9.32023 4.02 8.99023 4.07C10.9002 6.23 12.0002 9.05 12.0002 12C12.0002 14.95 10.9002 17.77 8.99023 19.93C9.32023 19.98 9.66023 20 10.0002 20C14.4102 20 18.0002 16.41 18.0002 12Z"
      />
      <path d="M5 20.65C6.47 21.5 8.18 22 10 22C15.52 22 20 17.52 20 12C20 6.48 15.52 2 10 2C8.18 2 6.47 2.5 5 3.35C7.99 5.08 10 8.3 10 12C10 15.7 7.99 18.92 5 20.65ZM12 12C12 9.05 10.9 6.23 8.99 4.07C9.32 4.02 9.66 4 10 4C14.41 4 18 7.59 18 12C18 16.41 14.41 20 10 20C9.66 20 9.32 19.98 8.99 19.93C10.9 17.77 12 14.95 12 12Z" />
    </svg>
  )
}
