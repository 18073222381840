import { SVGProps } from "react"
import c from "clsx"

export default function View_carousel({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="view-carousel"
      fill="white"
      className={c(["fill-current"], [className && className])}
      {...props}
    >
      <path d="M18 6H22V17H18V6ZM7 19H17V4H7V19ZM9 6H15V17H9V6ZM2 6H6V17H2V6Z" />
      <path opacity="0.3" d="M9 6H15V17H9V6Z" />
    </svg>
  )
}
