import c from 'clsx'
import ProgressBar from '../../../../atoms/ProgressBar'
import Modal from '../../../../organisms/Modal'
import skills from '../data/skills.json'

interface LanguagesSkillsProps {
  className?: string
}

interface LanguageProps {
  name: string
  percent: number
  level: string
}

const LanguagesSkills = ({ className }: LanguagesSkillsProps): JSX.Element => {
  const Language = ({ name, percent, level }: LanguageProps) => {
    return (
      <div key={name} className="w-full">
        <p className="text-sm lg:text-base font-normal dark:text-White80 mb-1">
          {name}
        </p>
        <ProgressBar curent={percent} max={100} className="mb-2" />
        <p className="text text-xs dark:text-Grey ">{level}</p>
      </div>
    )
  }

  return (
    <Modal.Wrapper className={className}>
      <Modal.Body className="p-4 lg:p-8">
        <h3 className="text-base lg:text-xl font-semibold dark:text-White50 mb-4">
          Languages
        </h3>
        <div className="flex gap-6 mb-9">
          <Language name="Russian" percent={100} level="Native speaker" />
          <Language name="English" percent={65} level="Intermediate" />
          <Language name="Estonian" percent={10} level="Elementary" />
        </div>

        <h3 className="text-base lg:text-xl font-semibold dark:text-White50 mb-4">
          Skills
        </h3>
        <div>
          {skills.data.primary.map(skill => (
            <div
              key={skill}
              className={c(
                'mr-3 mb-3 inline-flex px-3 py-1 border rounded-xl font-semibold text-xs',
                'border-PrimaryLight30 text-Primary',
                'dark:border-dark-SuccessLighter dark:text-dark-Primary'
              )}
            >
              {skill}
            </div>
          ))}
        </div>
        <div>
          {skills.data.secondary.map(skill => (
            <div
              key={skill}
              className={c(
                'mr-3 mb-3 inline-flex px-3 py-1 border rounded-xl font-semibold text-xs',
                'border-PrimaryLight30 text-PrimaryLight10',
                'dark:border-dark-SuccessLighter dark:text-Green30'
              )}
            >
              {skill}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal.Wrapper>
  )
}

export default LanguagesSkills
