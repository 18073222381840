import c from 'clsx'
import MoonIcon from './Icon/icons/Brightness2'
import SunIcon from './Icon/icons/Brightness5'
import Menu from './Icon/icons/Menu'

interface HeaderProps {
  navHandler: () => void
}

const themeHandler = () => {
  const current = localStorage.getItem('theme')

  if (current === 'dark') {
    localStorage.theme = 'light'
    document.documentElement.classList.remove('dark')
  } else {
    localStorage.theme = 'dark'
    document.documentElement.classList.add('dark')
  }
}

export const Header = ({ navHandler }: HeaderProps): JSX.Element => (
  <div
    className={c(
      [
        'flex justify-between h-header-xl items-center px-4 shadow-header dark:shadow-none'
      ],
      ['bg-White'],
      ['dark:bg-dark-White ']
    )}
  >
    <div className="flex">
      <button className="mr-4" onClick={navHandler}>
        <Menu
          width={32}
          height={32}
          className={c(
            ['transition-colors'],
            ['text-Primary hover:text-PrimaryDark'],
            ['dark:text-dark-Primary dark:hover:text-dark-PrimaryDark']
          )}
        />
      </button>

      <div className="h-header-icon rounded overflow-hidden">
        <img
          className="max-h-full"
          src="/photo.webp"
          alt=""
          loading="lazy"
          width="45"
          height="45"
        />
      </div>

      <div className="my-auto ml-2.5">
        <div
          className={c(
            ['font-bold'],
            ['text-LightBloom250'],
            ['dark:text-dark-LightBloom250']
          )}
        >
          Viktor Iukhlin
        </div>
        <div className="text-xs lg:text-sm">Software Developer</div>
      </div>
    </div>
    <button className="relative h-6 select-none" onClick={themeHandler}>
      <div
        className={c(
          ['flex items-center gap-3 transform transition-all duration-400'],
          ['rotate-0'],
          ['dark:rotate-180']
        )}
      >
        <SunIcon className="text-WarningDark" />
        <MoonIcon className="text-InfoDark" />
      </div>
      <div
        className={c(
          [
            'absolute -left-1 -top-1 bottom-0 bg-transparent rounded-full w-8 h-8 border-2'
          ],
          ['border-Warning shadow-sun'],
          ['dark:border-Info dark:shadow-moon ']
        )}
      ></div>
    </button>
  </div>
)
