export const KEYS = {
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  A: 'KeyA',
  D: 'KeyD',
  SPACE: 'Space'
}

export const GAME = {
  WIDTH: 1280,
  HEIGHT: 720
}
