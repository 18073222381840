import clsx from "clsx"
import { FC } from "react"

interface ArrowProps {
  active: boolean
  current: boolean
}

export const OpenArrow: FC<ArrowProps> = ({ active, current }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={clsx(
        ["fill-current transition"],
        [
          current
            ? "text-Primary dark:text-dark-Primary"
            : "text-LightBloom250 dark:text-dark-LightBloom250"
        ],
        [active && "transform rotate-180"]
      )}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" />
    </svg>
  )
}
