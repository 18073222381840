import c from 'clsx'
import { SVGProps } from 'react'

export default function ArrowUp({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="arrow-top"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M20.1299 17.5098L21.8999 15.7298L11.9999 5.83984L2.0999 15.7398L3.8699 17.5098L11.9999 9.37984L20.1299 17.5098V17.5098Z" />
    </svg>
  )
}
