import { ISvg } from './interfaces'

export default function Stars({ size, className, style }: ISvg): JSX.Element {
  return (
    <svg
      width={size * 1.5}
      height={size}
      viewBox="0 0 24 16"
      fill="#D9D9D9"
      className={className}
      style={style}
    >
      <path d="M16 0L16.1591 0.389733C16.2428 0.594626 16.4054 0.757226 16.6103 0.84088L17 1L16.6103 1.15912C16.4054 1.24277 16.2428 1.40537 16.1591 1.61027L16 2L15.8409 1.61027C15.7572 1.40537 15.5946 1.24277 15.3897 1.15912L15 1L15.3897 0.84088C15.5946 0.757226 15.7572 0.594626 15.8409 0.389733L16 0Z" />
      <path d="M9.29318 11.3037L9.28371 11.6267C9.27899 11.7879 9.33934 11.9442 9.45115 12.0603L9.67522 12.2932L9.35222 12.2837C9.19105 12.279 9.03476 12.3393 8.91858 12.4511L8.68576 12.6752L8.69522 12.3522C8.69995 12.191 8.6396 12.0348 8.52779 11.9186L8.30371 11.6858L8.62671 11.6952C8.78788 11.6999 8.94418 11.6396 9.06035 11.5278L9.29318 11.3037Z" />
      <path d="M6.29318 2.30371L6.28371 2.62671C6.27899 2.78788 6.33934 2.94417 6.45115 3.06035L6.67522 3.29317L6.35222 3.28371C6.19105 3.27899 6.03476 3.33933 5.91858 3.45114L5.68576 3.67522L5.69522 3.35222C5.69995 3.19105 5.6396 3.03476 5.52779 2.91858L5.30371 2.68576L5.62671 2.69522C5.78788 2.69994 5.94418 2.6396 6.06035 2.52779L6.29318 2.30371Z" />
      <path d="M0.744463 11.2806L0.957634 11.5234C1.064 11.6446 1.21467 11.7179 1.37567 11.7267L1.69832 11.7444L1.45547 11.9576C1.33429 12.064 1.26102 12.2147 1.25219 12.3757L1.23447 12.6983L1.0213 12.4555C0.914933 12.3343 0.764267 12.261 0.603268 12.2522L0.280615 12.2345L0.523466 12.0213C0.644645 11.9149 0.717912 11.7643 0.726751 11.6033L0.744463 11.2806Z" />
      <path d="M3.2535 4.10232V4.10232C3.44781 4.20707 3.67611 4.2292 3.88693 4.16372V4.16372V4.16372C3.78218 4.35804 3.76005 4.58633 3.82553 4.79715V4.79715V4.79715C3.63121 4.6924 3.40292 4.67027 3.1921 4.73575V4.73575V4.73575C3.29685 4.54143 3.31898 4.31314 3.2535 4.10232V4.10232Z" />
      <path d="M23.3898 14.0224V14.0224C23.5028 14.1828 23.6757 14.291 23.8694 14.3225V14.3225V14.3225C23.709 14.4355 23.6008 14.6084 23.5693 14.802V14.802V14.802C23.4563 14.6417 23.2834 14.5335 23.0898 14.502V14.502V14.502C23.2502 14.389 23.3583 14.2161 23.3898 14.0224V14.0224Z" />
      <path d="M19.2436 6.05611L19.3299 6.17849C19.3454 6.20046 19.369 6.21527 19.3956 6.21958L19.5434 6.24362L19.421 6.32989C19.399 6.34537 19.3842 6.36904 19.3799 6.39557L19.3558 6.54335L19.2696 6.42097C19.2541 6.399 19.2304 6.38419 19.2039 6.37988L19.0561 6.35584L19.1785 6.26957C19.2005 6.25409 19.2153 6.23042 19.2196 6.20389L19.2436 6.05611Z" />
      <path d="M11.1374 13.5372L11.5012 13.9016C11.6151 14.0157 11.7702 14.0791 11.9314 14.0776L12.4463 14.0728L12.0819 14.4366C11.9678 14.5505 11.9043 14.7056 11.9058 14.8668L11.9106 15.3817L11.5468 15.0173C11.4329 14.9032 11.2779 14.8397 11.1166 14.8412L10.6018 14.846L10.9662 14.4822C11.0803 14.3683 11.1437 14.2133 11.1422 14.052L11.1374 13.5372Z" />
      <path d="M23.1598 2.9349L23.1436 3.04792C23.1124 3.26701 23.1706 3.48947 23.3052 3.66514L23.3746 3.75577L23.2616 3.73964C23.0425 3.70837 22.8201 3.7666 22.6444 3.9012L22.5538 3.97064L22.5699 3.85762C22.6012 3.63852 22.5429 3.41607 22.4083 3.24039L22.3389 3.14977L22.4519 3.1659C22.671 3.19717 22.8935 3.13894 23.0691 3.00433L23.1598 2.9349Z" />
      <path d="M11.2436 8.05611L11.3299 8.17849C11.3454 8.20046 11.369 8.21527 11.3956 8.21958L11.5434 8.24362L11.421 8.32989C11.399 8.34537 11.3842 8.36904 11.3799 8.39557L11.3558 8.54335L11.2696 8.42097C11.2541 8.399 11.2304 8.38419 11.2039 8.37988L11.0561 8.35584L11.1785 8.26957C11.2005 8.25409 11.2153 8.23042 11.2196 8.20389L11.2436 8.05611Z" />
    </svg>
  )
}
