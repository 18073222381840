import c from 'clsx'
import { useState } from 'react'
import useBreakpoint from '../../../../lib/hooks/useBreakpoint'
import { IProject } from '../../../../lib/models'
import { ComponentProps } from '../../../../routes'
import Icon from '../../../organisms/Icon'
import Modal from '../../../organisms/Modal'
import Label from './Label'
import Display from './Monitor/Display'
import ItemModal from './ProjectModal'
import { projects } from './data'

//COMPONENT
const Projects = ({ navActive }: ComponentProps): JSX.Element => {
  const [hoverProjectId, setHoverProjectId] = useState<number | null>(null)
  const [activeProject, setActiveProject] = useState<IProject | null>(null)
  const { md, lg } = useBreakpoint()

  return (
    <div className="h-full lg:flex lg:flex-row">
      {lg && (
        <div className="h-full mr-10 w-7/12 flex flex-col justify-center">
          <Display
            navActive={navActive}
            currentProjectId={hoverProjectId || activeProject?.id || -1}
          ></Display>
        </div>
      )}

      <Modal.Wrapper className="mb-4 h-full lg:mb-0 lg:w-5/12" key="modal_2">
        <Modal.Header leftBox="Projects" />
        <Modal.Body className=" lg:max-h-modal-content-xl">
          {projects.map(project => (
            <button
              key={project.title}
              onClick={() => {
                setHoverProjectId(null)
                setActiveProject(project)
              }}
              onMouseLeave={() => setHoverProjectId(null)}
              onMouseOver={() => setHoverProjectId(project.id)}
              className={c(
                [
                  'pl-2 pr-2 font-bold text-sm w-full py-2 border-b lg:py-3 relative overflow-hidden'
                ],
                ['border-Black border-opacity-10 hover:bg-LightBloom50'],
                [
                  'dark:border-dark-Black dark:border-opacity-10 dark:hover:bg-White10'
                ]
              )}
            >
              <div
                className="flex items-center transition-transform duration-400"
                style={{
                  transform: `translate(${
                    project.id === hoverProjectId ||
                    project.id === activeProject?.id
                      ? '-32px'
                      : 0
                  })`
                }}
              >
                <Icon icon="img" className="text-LightBloom150" />

                <div className="ml-2 mr-3">{project.title}</div>

                <Label type={project.badgeType} />
              </div>
            </button>
          ))}
        </Modal.Body>
      </Modal.Wrapper>

      <ItemModal
        onClose={() => setActiveProject(null)}
        item={activeProject}
        md={md}
        lg={lg}
      />
    </div>
  )
}

export default Projects
