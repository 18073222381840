import 'leaflet/dist/leaflet.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import useBreakpoint from '../../../../lib/hooks/useBreakpoint'
import { certificates } from './data'

//COMPONENT
const MyCertificates = (): JSX.Element => {
  const { md, lg } = useBreakpoint()

  return (
    <Swiper
      className="h-full"
      spaceBetween={lg ? 20 : 12}
      slidesPerView={lg ? 2.2 : md ? 1.5 : 1.1}
      mousewheel={{
        forceToAxis: true
      }}
    >
      {certificates.map(certificate => (
        <SwiperSlide
          key={`image-${certificate.id}`}
          className="cursor-grab rounded-2xl overflow-hidden  select-none bg-LightBloom100op50 dark:bg-White20 h-full"
        >
          <div className="px-5 py-2 bg-PrimaryLighter dark:bg-dark-White lg:h-24">
            <div className="text-sm lg:text-lg">{certificate.title}</div>
            <div className="text-xs lg:text-base">
              {certificate.description}
            </div>
          </div>
          <div className="h-certificates-xl p-4 relative flex flex-col justify-center">
            <img
              src={certificate.url}
              alt={certificate.title}
              className="max-h-full max-w-full m-auto"
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default MyCertificates
