import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx'
import Modal from '../../organisms/Modal'

import ProgressIndicator from './Components/ProgressIndicator'

import ProgressIndicatorTemplates from './Components/ProgressIndicator/template'
import SpinnerComponent from './Components/SpinnerComponent'
import SpinnerTemplates from './Components/SpinnerComponent/template'

interface CurrentComponent {
  component: null | JSX.Element
  jsxTemplate: string
  cssTemplate: string
}

const ReactComponents = (): JSX.Element => {
  const history = useHistory()
  const [currentComponent, setCurrentComponent] = useState<CurrentComponent>({
    component: null,
    jsxTemplate: '',
    cssTemplate: ''
  })

  useEffect(() => {
    SyntaxHighlighter.registerLanguage('jsx', jsx)
  }, [])

  useEffect(() => {
    switch (history.location.search) {
      case '?Spinner':
        setCurrentComponent({
          component: <SpinnerComponent />,
          jsxTemplate: SpinnerTemplates.tsx,
          cssTemplate: SpinnerTemplates.css
        })
        break

      case '?ProgressIndicator':
        setCurrentComponent({
          component: (
            <ProgressIndicator
              current={75}
              total={100}
              diameter={100}
              borderWidth={6}
            />
          ),
          jsxTemplate: ProgressIndicatorTemplates.tsx,
          cssTemplate: ProgressIndicatorTemplates.css
        })
        break
    }
  }, [history.location.search])

  return (
    <div className="scroll-custom flex xl:h-full flex-col xl:flex-row xl:overflow-visible">
      <Modal.Wrapper className="xl:w-7/12 h-full mb-5 xl:mb-0">
        <Modal.Header leftBox="TSX" />
        <Modal.Body className="lg:max-h-modal-content-xl px-5 xl:h-full max-h-min">
          <SyntaxHighlighter language="jsx" useInlineStyles={false}>
            {currentComponent.jsxTemplate}
          </SyntaxHighlighter>
        </Modal.Body>
      </Modal.Wrapper>

      <div className="xl:w-5/12 xl:h-full flex flex-col xl:pl-6">
        <Modal.Wrapper className="xl:h-4/6 mb-6">
          <Modal.Header leftBox="CSS" />
          <Modal.Body className="lg:max-h-modal-content-xl px-5 xl:h-full max-h-min">
            <SyntaxHighlighter language="css" useInlineStyles={false}>
              {currentComponent.cssTemplate}
            </SyntaxHighlighter>
          </Modal.Body>
        </Modal.Wrapper>

        <Modal.Wrapper className="xl:h-2/6 mb-5 lg:mb-9 xl:mb-0">
          <Modal.Header leftBox="Preview" />
          <Modal.Body className="lg:max-h-modal-content-xl p-6 centering xl:h-full max-h-min">
            {currentComponent?.component}
          </Modal.Body>
        </Modal.Wrapper>
      </div>
    </div>
  )
}

export default ReactComponents
