import c from 'clsx'
import { SVGProps } from 'react'

export default function Link({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      data-icon="link"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path
        opacity="0.87"
        d="M9.99986 5.646L8.25826 7.3876L9.12906 8.2584L10.8707 6.5168C11.5891 5.79839 12.7646 5.79839 13.4831 6.5168C14.2015 7.23521 14.2015 8.41078 13.4831 9.12919L11.7415 10.8708L12.6123 11.7416L14.3538 9.99999C15.5556 8.79829 15.5556 6.8477 14.3538 5.646C13.1521 4.4443 11.2016 4.4443 9.99986 5.646ZM10.8707 11.7416L9.12906 13.4832C8.41065 14.2016 7.23507 14.2016 6.51667 13.4832C5.79826 12.7648 5.79826 11.5892 6.51667 10.8708L8.25826 9.12919L7.38746 8.2584L5.64587 9.99999C4.44417 11.2017 4.44417 13.1523 5.64587 14.354C6.84757 15.5557 8.79816 15.5557 9.99986 14.354L11.7415 12.6124L10.8707 11.7416ZM7.82286 11.3062L11.3061 7.823L12.1769 8.69379L8.69366 12.177L7.82286 11.3062Z"
      />
    </svg>
  )
}
