import { SCREEN as s } from './constants'
import { ISymbol, ISymbolProps } from './interface'

export const createGradientObject = (
  ctx: CanvasRenderingContext2D,
  type: 'linear' | 'radial'
): CanvasGradient => {
  let gradient: CanvasGradient

  if (type === 'linear') {
    gradient = ctx.createLinearGradient(0, 0, s.WIDTH, s.HEIGHT)
    gradient.addColorStop(0, '#F4002C')
    gradient.addColorStop(0.2, '#FFAF39')
    gradient.addColorStop(0.4, '#51FD00')
    gradient.addColorStop(0.6, '#4ECAFF')
    gradient.addColorStop(0.8, '#0061F4')
    gradient.addColorStop(1, '#D939CD')
  } else {
    gradient = ctx.createRadialGradient(
      s.WIDTH / 2,
      s.HEIGHT / 2,
      100,
      s.WIDTH / 2,
      s.HEIGHT / 2,
      s.WIDTH / 2
    )

    gradient.addColorStop(0, '#F4002C97')
    gradient.addColorStop(0.5, '#51FD0097')
    gradient.addColorStop(1, '#0061F497')
  }

  return gradient
}

export const getDisplayHeight = () => {
  const frameSize = 16
  const navMenuSize = 280
  const marginRight = 40
  const areaPercentage = 7 / 12
  const mainPaddings = 36 * 2

  let browserWidth = window.innerWidth
  const navBar = localStorage.getItem('navBar') === 'Active' ? true : false

  if (navBar) {
    browserWidth -= navMenuSize
  }

  const displayWidth =
    (browserWidth - marginRight - mainPaddings) * areaPercentage

  return {
    height: displayWidth * 0.59,
    width: displayWidth,
    innerWidth: displayWidth - frameSize * 2,
    innerHeight: displayWidth * 0.59 - frameSize * 2
  }
}

const getSymbol = ({ x, y, fontSize, canvasHeight }: ISymbolProps): ISymbol => {
  return {
    characters: s.CHARACTERS,
    x: x,
    y: y,
    fontSize: fontSize,
    text: '',
    canvasHeight: canvasHeight,
    draw(context: CanvasRenderingContext2D) {
      this.text = this.characters.charAt(
        Math.floor(Math.random() * this.characters.length)
      )
      context.fillText(
        this.text,
        this.x * this.fontSize,
        this.y * this.fontSize
      )
      if (this.y * this.fontSize > this.canvasHeight && Math.random() > 0.98) {
        this.y = 0
      } else {
        this.y += 1
      }
    }
  }
}

export const getAnimationSymbols = (): ISymbol[] => {
  const symbols = []
  const columns = s.WIDTH / s.FONT_SIZE

  for (let i = 0; i < columns; i++) {
    symbols[i] = getSymbol({
      x: i,
      y: 0,
      fontSize: s.FONT_SIZE,
      canvasHeight: s.HEIGHT
    })
  }

  return symbols
}
