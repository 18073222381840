import c from 'clsx'
import { SVGProps } from 'react'

export default function ArrowBack({
  className,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      data-icon="arrow-back"
      fill="white"
      className={c(['fill-current '], [className && className])}
      {...props}
    >
      <path d="M6.49023 20.1301L8.26023 21.9001L18.1602 12.0001L8.26023 2.1001L6.49023 3.8701L14.6202 12.0001L6.49023 20.1301Z" />
    </svg>
  )
}
