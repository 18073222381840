import { random } from '../gameFunctions'
import { GAME, KEYS } from './constants'

import { IBlock, IGameObject, IPlatform } from './interfaces'

export const game: IGameObject = {
  text: null,
  running: false,
  width: GAME.WIDTH,
  height: GAME.HEIGHT,
  ctx: null,
  rows: 4,
  cols: 8,
  blockWidth: 111,
  blockHeight: 39,
  blocks: [],
  blocksLength: 0,
  score: 0,
  ball: null,
  platform: null,
  sprites: {
    background: null,
    ball: null,
    platform: null,
    block: null
  },
  sounds: {
    bump: null,
    win: null,
    gameOver: null,
    bumpPlatform: null
  },
  init() {
    this.running = true
    this.score = 0
    this.text = { message: 'PRESS SPACE TO START', x: 540, y: 550 }
    this.ball = { ...this.ball, dx: 0, dy: 0, x: 660, y: 610 }
    this.platform = {
      ...this.platform,
      ball: this.ball,
      dx: 0,
      x: 550,
      y: 650
    }

    if (!this.ctx) {
      this.ctx = (
        document.getElementById('my-canvas') as HTMLCanvasElement
      ).getContext('2d')
      this.setEvents()
      this.setTextFont()
    }
  },
  setTextFont() {
    if (this.ctx) {
      this.ctx.fillStyle = '#ffffff'
      this.ctx.font = '20px Arial'
    }
  },
  keyDownEvents(e: KeyboardEvent) {
    if (
      e.code === KEYS.LEFT ||
      e.code === KEYS.RIGHT ||
      e.code === KEYS.A ||
      e.code === KEYS.D
    ) {
      game.platform.start(e.code)
    } else if (e.code === KEYS.SPACE) {
      if (game.running) {
        game.platform.fire()
        if (game.text) game.text = null
      } else {
        game.start()
      }
    }
  },
  keyUpEvents() {
    game.platform.stop()
  },
  setEvents() {
    window.addEventListener('keydown', this.keyDownEvents)
    window.addEventListener('keyup', this.keyUpEvents)
  },
  preload(callback) {
    let loaded = 0
    const required =
      Object.keys(this.sprites).length + Object.keys(this.sounds).length

    const onResourceLoad = () => {
      ++loaded
      if (loaded >= required) {
        callback()
      }
    }

    this.preLoadSprites(onResourceLoad)
    this.preLoadAudio(onResourceLoad)
  },
  preLoadSprites(onResourceLoad: () => void) {
    for (const key in this.sprites) {
      this.sprites[key] = new Image()
      this.sprites[key].src = `arkanoidFiles/${key}.webp`
      this.sprites[key].addEventListener('load', onResourceLoad)
    }
  },
  preLoadAudio(onResourceLoad: () => void) {
    for (const key in this.sounds) {
      this.sounds[key] = new Audio(`arkanoidFiles/${key}.mp3`)
      this.sounds[key].addEventListener('canplaythrough', onResourceLoad, {
        once: true
      })
      this.sounds[key].load()
    }
  },
  render() {
    if (!this.ctx) return

    this.ctx.clearRect(0, 0, GAME.WIDTH, GAME.HEIGHT)
    this.ctx.drawImage(this.sprites.background, 0, 0)
    this.ctx.drawImage(this.sprites.ball, this.ball.x, this.ball.y)
    this.ctx.drawImage(this.sprites.platform, this.platform.x, this.platform.y)
    this.renderBlocks()

    this.ctx.fillText(`Score: ${this.score}`, 25, 44)
    if (this.text) {
      this.ctx.fillText(this.text.message, this.text.x, this.text.y)
    }
  },
  renderBlocks() {
    if (!this.ctx) return

    for (const block of this.blocks) {
      this.ctx.drawImage(this.sprites.block, block.x, block.y)
    }
  },
  create() {
    this.blocksLength = 0

    let id = 0
    for (let row = 0; row < this.rows; row++) {
      for (let col = 0; col < this.cols; col++) {
        this.blocks.push({
          id: id,
          width: 111,
          height: 39,
          x: 140 * col + 90,
          y: 66 * row + 100
        })
        id++
        this.blocksLength += 1
      }
    }
  },
  update() {
    this.collideBlocks()
    this.collidePlatform()
    this.ball.collideWorldBounds()
    this.platform.collideWorldBounds()
    this.platform.move()
    this.ball.move()
  },
  addScore() {
    ++this.score
    if (!this.blocks.length) {
      this.text = { message: 'YOU WIN!', x: 570, y: 300 }
      this.sounds.win.play()
      game.end()
    }
  },
  collideBlocks() {
    for (const block of this.blocks) {
      if (this.ball.collide(block)) {
        this.ball.bumpBlock(block)
        this.addScore()
        this.sounds.bump.play()
      }
    }
  },
  collidePlatform() {
    if (this.ball.collide(this.platform)) {
      this.sounds.bumpPlatform.play()
      this.ball.bumpPlatform(this.platform)
    }
  },
  run() {
    if (this.running) {
      window.requestAnimationFrame(() => {
        this.update()
        this.render()
        this.run()
      })
    }
  },
  start() {
    this.init()

    this.preload(() => {
      this.create()
      this.run()
    })
  },
  end() {
    this.running = false
  },
  remove() {
    this.running = false
    window.removeEventListener('keydown', this.keyDownEvents)
    window.removeEventListener('keyup', this.keyUpEvents)
    this.ctx = null
  }
}

//  Ball
game.ball = {
  height: 40,
  width: 40,
  velocity: 8,
  dx: 0,
  dy: 0,
  x: 660,
  y: 610,
  start() {
    this.dy = -this.velocity
    this.dx = random(-this.velocity, this.velocity)
  },
  move() {
    if (this.dy) {
      this.y += this.dy
    }
    if (this.dx) {
      this.x += this.dx
    }
  },
  collide(element: IPlatform) {
    const x = this.x + this.dx
    const y = this.y + this.dy

    if (
      x + this.width > element.x &&
      x < element.x + element.width &&
      y + this.height > element.y &&
      y < element.y + element.height
    ) {
      return true
    }

    return false
  },
  collideWorldBounds() {
    const ballLeft = this.x + this.dx
    const ballRight = ballLeft + this.width
    const ballTop = this.y + this.dy
    const ballBottom = ballTop + this.height

    if (ballLeft < 0) {
      this.x = 0
      this.dx = this.velocity
    } else if (ballRight > game.width) {
      this.x = game.width - this.width
      this.dx = -this.velocity
    } else if (ballTop < 0) {
      this.y = 0
      this.dy = this.velocity
    } else if (ballBottom > game.height) {
      game.text = { message: 'YOU LOSE!', x: 580, y: 450 }
      game.sounds.gameOver.play()
      game.end()
    }
  },
  bumpBlock(block: IBlock) {
    this.dy *= -1
    game.blocks = game.blocks.filter((b: IBlock) => b.id !== block.id)
  },
  bumpPlatform(platform: IPlatform) {
    if (this.dy > 0) {
      this.dy = -this.velocity
      const touchX = this.x + this.width / 2
      this.dx = this.velocity * platform.getTouchOffset(touchX)
    }
  }
}

//  Platform
game.platform = {
  width: 251,
  height: 41,
  ball: game.ball,
  velocity: 12,
  dx: 0,
  x: 550,
  y: 650,
  fire() {
    if (this.ball) {
      this.ball.start()
      this.ball = null
    }
  },
  start(direction: string) {
    if (direction === KEYS.LEFT || direction === KEYS.A) {
      this.dx = -this.velocity
    } else if (direction === KEYS.RIGHT || direction === KEYS.D) {
      this.dx = this.velocity
    }
  },
  stop() {
    this.dx = 0
  },
  move() {
    if (this.dx) {
      this.x += this.dx
      if (this.ball) {
        this.ball.x += this.dx
      }
    }
  },
  getTouchOffset(x: number) {
    const diff = this.x + this.width - x
    const offset = this.width - diff
    const result = (2 * offset) / this.width
    return result - 1
  },
  collideWorldBounds() {
    const platformLeft = this.x + this.dx
    const platformRight = platformLeft + this.width

    if (platformLeft < 0 || platformRight > game.width) {
      this.dx = 0
    }
  }
}
